<template>
  <div class="attach-and-trace">
    <div class="attach-section" v-if="attachFlag">
      <div class="flex">
        <div class="sub-titles">附件</div>
        <Button @click="reqAttach" icon="md-refresh" size="small" style="margin-left: 10px"></Button>
        <input @change="onFiles" multiple ref="inputFiles" style="display: none" type="file" />
        <Button
          :loading="attachPosting"
          @click="
            () => {
              $refs.inputFiles.click();
            }
          "
          icon="md-add"
          size="small"
          style="margin-left: 10px"
          type="primary"
          v-if="$refs.inputFiles"
        >选择文件</Button>
      </div>
      <div class="bk-loading-line" style="padding: 20px 0 0" v-if="attachLoading">
        <Icon type="ios-loading"></Icon>
        <span>Loading</span>
      </div>
      <div
        class="text-sub"
        style="padding: 20px 0 0"
        v-if="!attachLoading && attachLst.length == 0"
      >暂无数据</div>
      <div :key="`attach-${i}`" class="attach-item" v-for="(item, i) in attachLst">
        <a :href="item.Url" class="text-primary" target="_blank">{{item.Name}}</a>
        <Icon
          @click="delAttach(item)"
          class="pointer"
          color="#ed4014"
          size="18"
          type="md-close"
          v-if="delAttachFlag"
        />
        <div class="text-sub text-xs">
          {{ item.CreateUserName }} / {{ item.CreateTime }}
          {{ item.Tips ? " / " + item.Tips : "" }}
        </div>
      </div>
    </div>

    <div class="trace-section" v-if="traceFlag">
      <div class="flex">
        <div class="sub-titles">消息</div>
        <Button @click="reqFlowTrace" icon="md-refresh" size="small" style="margin-left: 10px"></Button>
        <Button
          @click="open()"
          size="small"
          style="margin-left: 10px"
          type="primary"
          v-if="tracAddFlag"
        >
          <Icon type="md-add" />发布消息
        </Button>
      </div>
      <div class="bk-loading-line" style="padding: 20px 0 0" v-if="traceLoading">
        <Icon type="ios-loading"></Icon>
        <span>Loading</span>
      </div>
      <div
        class="text-sub"
        style="padding: 20px 0 0"
        v-if="!traceLoading && traceLst.length == 0"
      >暂无数据</div>
      <div :key="`trace-${i}`" class="trace-item" v-for="(a, i) in traceLst">
        <div class="left">
          <div class="info">
            {{ a.Content
            }}
            <img
              :src="a.SignImg"
              @click="viewImg(a.SignImg)"
              class="sign-img"
              v-if="a.SignImg"
            />
          </div>
          <div class="sub-info">
            <span v-if="a.Party == 1">委托方</span>
            <span v-if="a.Party == 2">倍特睿</span>
            <span>- {{ a.CreateUserName }} / {{ a.CreateTime }}</span>
          </div>
        </div>
        <div :key="`trace-${i}-${j}`" class="right" v-for="(b, j) in a.Replys">
          <div class="info">
            {{ b.Content
            }}
            <img
              :src="b.SignImg"
              @click="viewImg(b.SignImg)"
              class="sign-img"
              v-if="b.SignImg"
            />
          </div>
          <div class="sub-info">
            <span v-if="b.Party == 1">委托方</span>
            <span v-if="b.Party == 2">倍特睿</span>
            <span>- {{ b.CreateUserName }} / {{ b.CreateTime }}</span>
          </div>
        </div>
        <div class="right" v-if="a.Party == 1 && !a.Replys">
          <div @click="open(a.Id)" class="to-comment">回复</div>
        </div>
      </div>
    </div>

    <Modal @on-cancel="close" title="发布消息" v-model="flag" width="400">
      <Form :model="traceData" :rules="rule" @submit.native.prevent ref="traceForm">
        <FormItem prop="Content" style="margin: 0">
          <Input :rows="3" placeholder="请输入内容" type="textarea" v-model="traceData.Content" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="tracePosting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      OrderId: 0,
      FlowId: 0,
      exParam: {},
      // ——————————
      attachFlag: false,
      traceFlag: false,
      tracAddFlag: false,
      // ——————————
      attachLoading: false,
      attachPosting: false,
      attachLst: [],
      traceLoading: false,
      traceLst: [],
      // ——————————
      flag: false,
      tracePosting: false,
      traceData: {},
      rule: { Content: [{ required: true, message: " " }] },
      // ——————————
      delAttachFlag: false,
    };
  },
  methods: {
    init(orderid, flowid, conf, exParam) {
      if (conf) {
        typeof conf.attachFlag == "boolean"
          ? (this.attachFlag = conf.attachFlag)
          : (this.attachFlag = true);
        typeof conf.traceFlag == "boolean"
          ? (this.traceFlag = conf.traceFlag)
          : (this.traceFlag = true);
        typeof conf.tracAddFlag == "boolean"
          ? (this.tracAddFlag = conf.tracAddFlag)
          : (this.tracAddFlag = true);
        typeof conf.delAttachFlag == "boolean"
          ? (this.delAttachFlag = conf.delAttachFlag)
          : (this.delAttachFlag = false);
      } else {
        this.attachFlag = true;
        this.traceFlag = true;
        this.tracAddFlag = true;
        this.delAttachFlag = false;
      }
      this.exParam = exParam || {};
      this.OrderId = orderid;
      this.FlowId = flowid;
      if (this.attachFlag) {
        this.reqAttach();
      }
      if (this.traceFlag) {
        this.reqFlowTrace();
      }
    },
    clear() {
      this.attachFlag = false;
      this.attachLoading = false;
      this.attachPosting = false;
      this.attachLst = [];
      this.traceFlag = false;
      this.traceLoading = false;
      this.tracePosting = false;
      this.traceLst = [];
    },
    // 附件
    reqAttach() {
      this.attachLst = [];
      this.attachLoading = true;
      this.$axios
        .get("/Api/DataAttachment/QueryForFlow", {
          params: Object.assign(
            {
              FlowId: this.FlowId,
              OrderId: this.OrderId,
            },
            this.exParam
          ),
        })
        .then((res) => {
          this.attachLoading = false;
          if (res.result) {
            this.attachLst = res.data;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attachLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onFiles(e) {
      if (e.target.files.length > 0) {
        let files = [];
        for (let i = 0; i < e.target.files.length; i++) {
          files.push(e.target.files[i]);
        }
        e.target.value = "";
        this.saveAttach(files);
      }
    },
    saveAttach(files) {
      let formdata = new FormData();
      formdata.append("OrderId", this.OrderId);
      formdata.append("FlowId", this.FlowId);
      for (let file of files) {
        formdata.append("File", file);
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.attachPosting = true;
      this.$axios
        .post("/File/PostFlowObject", formdata, config)
        .then((res) => {
          this.attachPosting = false;
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqAttach();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attachPosting = false;
          this.$Message.error(err.errormsg);
        });
    },
    delAttach(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.$axios
            .post("/Api/DataAttachment/Delete", { Ids: row.Id })
            .then((res) => {
              if (res.result) {
                this.$Message.success("删除成功");
                this.reqAttach();
              } else {
                if (res.errormsg) {
                  this.$Message.error(res.errormsg);
                }
              }
            })
            .catch((err) => {
              if (err.errormsg) {
                this.$Message.error(err.errormsg);
              }
            });
        },
      });
    },
    // 消息
    reqFlowTrace() {
      this.traceLst = [];
      this.traceLoading = true;
      this.$axios
        .get("/Api/BtmsFlowTrace/QueryPg", {
          params: {
            OrderId: this.OrderId,
            FlowId: this.FlowId,
            PageIndex: 1,
            PageSize: 99,
          },
        })
        .then((res) => {
          this.traceLoading = false;
          if (res.result) {
            this.traceLst = res.data.DataSet;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.traceLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    open(TraceId) {
      this.flag = true;
      this.traceData.TraceId = TraceId || 0;
    },
    close() {
      this.flag = false;
      this.tracePosting = false;
      this.traceData = {};
    },
    ok() {
      this.$refs.traceForm.validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          formdata.append("OrderId", this.OrderId);
          formdata.append("FlowId", this.FlowId);
          formdata.append("TraceId", this.traceData.TraceId);
          formdata.append("Content", this.traceData.Content);
          // for (let file of files) {
          //   formdata.append("File", file);
          // }
          let config = { headers: { "Content-Type": "multipart/form-data" } };
          this.tracePosting = true;
          this.$axios
            .post("/BtmsOrderEx/AddFlowTrace", formdata, config)
            .then((res) => {
              this.tracePosting = false;
              if (res.result) {
                this.$Message.success("发布成功");
                this.close();
                this.reqFlowTrace();
              } else {
                this.$Message.error(res.errormsg);
              }
            })
            .catch((err) => {
              this.tracePosting = false;
              this.$Message.error(err.errormsg);
            });
        }
      });
    },
    viewImg(img) {
      this.$root.$emit("view-imgs", [img]);
    },
  },
};
</script>
<style lang="less">
.attach-and-trace {
  .attach-section + .trace-section {
    margin-top: 30px;
  }
  .sub-titles {
    font-weight: bold;
    position: relative;
  }
  .sub-titles::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2em;
    background-color: #2d8cf0;
    bottom: 0;
    left: 0;
  }

  .attach-item {
    margin: 10px 0;
    a {
      text-decoration: underline;
    }
  }
  .trace-item {
    padding: 5px 10px;
    .info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    .right .info {
      justify-content: flex-end;
    }
  }
}
</style>
