export default {
    data() {
        return {
            LabLst: [],
            LabMap: {},
            LabLoading: false
        }
    },
    methods: {
        reqLab() {
            this.LabLoading = true
            this.$axios
                .get("/Api/BaseLabs/QueryAll")
                .then((res) => {
                    this.LabLoading = false;
                    if (res.result && res.data) {
                        for (let i of res.data) {
                            this.LabMap[i.Id] = i.Name
                        }
                        this.LabLst = res.data;
                    }
                })
                .catch((err) => {
                    this.LabLoading = false;
                });
        }
    }
}