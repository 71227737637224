<template>
  <div class="bk-page" id="OrderItems">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form @submit.native.prevent class="bk-page-search" inline>
          <!-- <FormItem :label-width="56" label="委托单">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
            <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem> -->
          <FormItem :label-width="56" label="实验室">
            <Select clearable v-model="searchData1.LabId">
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem :label-width="68" label="委托单号">
            <Input v-model="searchData1.OrderCode" />
          </FormItem>
          <FormItem :label-width="68" label="项目名称">
            <Input v-model="searchData1.Name" />
          </FormItem>
          <FormItem :label-width="68" label="项目编码">
            <Input v-model="searchData1.Code" />
          </FormItem>
          <FormItem :label-width="40" label="状态">
            <Select clearable v-model="searchData1.Status">
              <Option :key="item.key" :value="item.key" v-for="item in $designConst.orderItems.StatusLst">{{ item.name
              }}</Option>
            </Select>
          </FormItem>
          <FormItem :label-width="82" label="检测工程师">
            <Input :value="searchData1.EngrName" @on-clear="unPickEngr" clearable readonly>
            <Button @click="toPickEngr" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem :label-width="82" label="委托单时段" style="width:282px">
            <DatePicker style="width:200px" type="daterange" v-model="searchData1.daterange" />
          </FormItem>
          <FormItem class="btns">
            <Button @click="onSearch()" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.BtmsTestItemAdd">
              <Icon type="md-add" />新增
            </Button>
            <Button @click="onSearch('export')" type="success" v-if="$able.map.BtmsTestItemExpt">导出</Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page :current="pageIndex" :page-size="pageSize" :total="recordCount"
              @on-change="(index) => onChangePage(index, 0)" @on-page-size-change="(size) => onChangePage(1, size)"
              show-elevator show-sizer show-total transfer />
      </div>
    </div>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-user :type="1" @on-ok="onPickEngr" ref="PickUser" title="选择检测工程师" />
    <edit @on-add="onChangePage(1)" @on-sub="onSub" @on-upd="reqTable" ref="edit" />
    <sub-item @to-add-sub="toAddSub" @to-cancel-sub="doCancel" @to-del-sub="doDel" @to-upd-sub="toUpdSub"
              ref="subItem" />
    <pre-start @on-flow="reqTable" ref="preStart" />
    <data-and-process @on-flow="reqTable" ref="dataAndProcess" />
    <outline-change @on-flow="reqTable" ref="outlineChange" />
    <stop @on-flow="reqTable" ref="stop" />
    <start @on-flow="reqTable" ref="start" />
    <change-syllabus @on-flow="reqTable" ref="changeSyllabus" />
  </div>
</template>
<script>
import SelectLabId from "@/components/select-labid.js";
import PickUser from "../../components/PickUser.vue";
import PickOrder from "../../components/PickOrder.vue";
import edit from "./edit";
import subItem from "./sub";
import preStart from "./pre-start/index.vue";
import dataAndProcess from "./testing/data-and-process.vue";
import outlineChange from "./outline-change/index.vue";
import stop from "./stop.vue";
import start from "./pre-start/start.vue";
import changeSyllabus from "../testingSyllabus/change.vue";
export default {
  mixins: [SelectLabId],
  components: {
    PickOrder,
    PickUser,
    edit,
    subItem,
    preStart,
    dataAndProcess,
    outlineChange,
    start,
    stop,
    changeSyllabus
  },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "委托单", key: "OrderCode" },
        { title: "项目名称", key: "Name" },
        // { title: "项目编码", key: "Code" },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            let a = [
              h("Badge", {
                props: {
                  status: this.$designConst.orderItems.StatusBadge[
                    params.row.Status
                  ],
                  // text: this.$designConst.orderItems.Status[params.row.Status],
                  text: params.row.StatusDescr
                }
              })
            ];
            if (params.row.Status == 3 && this.$able.map.BtmsTestItemStop) {
              a.push(
                h("Icon", {
                  props: { type: "md-pause", size: "16", color: "#2d8cf0" },
                  style: {
                    marginLeft: "5px",
                    cursor: "pointer",
                    backgroundColor: "rgba(237, 64, 20, .1)",
                    borderRadius: "2px"
                  },
                  on: {
                    click: () => {
                      this.$refs.stop.open(params.row.Id);
                    }
                  }
                })
              );
            }
            if (params.row.Status == 4 && this.$able.map.BtmsTestItemStart) {
              a.push(
                h("Icon", {
                  props: { type: "md-play", size: "14", color: "#ed4014" },
                  style: {
                    marginLeft: "5px",
                    cursor: "pointer",
                    backgroundColor: "rgba(45, 140, 240, .1)",
                    borderRadius: "2px",
                    padding: "1px 0 1px 2px",
                  },
                  on: {
                    click: () => {
                      this.$refs.start.open(params.row.Id);
                    }
                  }
                })
              );
            }
            return a;
          }
        },
        {
          title: "计划时间",
          width: 120,
          render: (h, params) => {
            return [
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: params.row.PlanBeginTime ? "#515a6e" : "#c5c8ce",
                  },
                },
                "开始：" +
                (params.row.PlanBeginTime
                  ? params.row.PlanBeginTime.substring(0, 10)
                  : "--")
              ),
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: params.row.PlanEndTime ? "#515a6e" : "#c5c8ce",
                  },
                },
                "结束：" +
                (params.row.PlanEndTime
                  ? params.row.PlanEndTime.substring(0, 10)
                  : "--")
              ),
            ];
          },
        },
        {
          title: "实际时间",
          width: 120,
          render: (h, params) => {
            let color1 = "#c5c8ce";
            if (params.row.BeginTime) {
              if (params.row.PlanBeginTime) {
                color1 =
                  params.row.PlanBeginTime.substring(0, 10).replace(/\-/g, "") <
                    params.row.BeginTime.substring(0, 10).replace(/\-/g, "")
                    ? "#ed4014"
                    : "#19be6b";
              } else {
                color1 = "#515a6e";
              }
            }
            let color2 = "#c5c8ce";
            if (params.row.EndTime) {
              if (params.row.PlanEndTime) {
                color2 =
                  params.row.PlanEndTime.substring(0, 10).replace(/\-/g, "") <
                    params.row.EndTime.substring(0, 10).replace(/\-/g, "")
                    ? "#ed4014"
                    : "#19be6b";
              } else {
                color2 = "#515a6e";
              }
            }
            return [
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: color1,
                  },
                },
                "开始：" +
                (params.row.BeginTime
                  ? params.row.BeginTime.substring(0, 10)
                  : "--")
              ),
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: color2,
                  },
                },
                "结束：" +
                (params.row.EndTime
                  ? params.row.EndTime.substring(0, 10)
                  : "--")
              ),
            ];
          },
        },
        { title: "检测工程师", key: "EngrName" },
        {
          title: "分包机构",
          key: "SubContractorName",
          width: 100,
          render: (h, params) =>
            h(
              "span",
              {
                style: {
                  color: params.row.SubContractorName ? "#515a6e" : "#c5c8ce",
                },
              },
              params.row.SubContractorName || "--"
            ),
        },
        {
          title: "操作",
          width: 200,
          align: "right",
          render: (h, params) => {
            let a = [];
            if (
              this.$able.map.BtmsTestItemSyllabusChg &&
              params.row.FlowCode != "TESTING-SYLLABUS-SIGN-AGAIN" &&
              (params.row.Status == 3 || params.row.Status == 4)
            ) {
              a.push(
                h(
                  "span",
                  {
                    style: {
                      cursor: "pointer",
                      color: "#2d8cf0",
                      display: "inline-block",
                      "vertical-align": "middle"
                    },
                    on: {
                      click: () => {
                        this.$refs.changeSyllabus.open({
                          ItemName: params.row.Name,
                          ItemId: params.row.Id
                        })
                      }
                    }
                  },
                  "变更测试大纲"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (params.row.FlowCode == "TESTING-SYLLABUS-CHGING") {
              a.push(
                h(
                  "span",
                  {
                    style: {
                      cursor: "pointer",
                      color: "#2d8cf0",
                      display: "inline-block",
                      "vertical-align": "middle"
                    },
                    on: {
                      click: () => {
                        this.$refs.outlineChange.open({
                          FlowId: params.row.FlowId,
                          FlowCode: params.row.FlowCode,
                          OrderId: params.row.OrderId,
                          OrderCode: params.row.OrderCode,
                          ItemId: params.row.Id,
                          ItemName: params.row.Name
                        });
                      }
                    }
                  },
                  "测试大纲变更"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (params.row.Status > 2 && params.row.Status <= 6) {
              a.push(
                h(
                  "span",
                  {
                    style: {
                      cursor: "pointer",
                      color: "#2d8cf0",
                      display: "inline-block",
                      "vertical-align": "middle"
                    },
                    on: {
                      click: () => {
                        this.$refs.dataAndProcess.open({
                          OrderId: params.row.OrderId,
                          ItemId: params.row.Id,
                          ItemName: params.row.Name
                        });
                      }
                    }
                  },
                  "数据&进度"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (params.row.Status >= 2) {
              a.push(
                h(
                  "span",
                  {
                    style: {
                      cursor: "pointer",
                      color: "#2d8cf0",
                      display: "inline-block",
                      "vertical-align": "middle"
                    },
                    on: {
                      click: () => {
                        this.$refs.preStart.open({
                          FlowId: params.row.FlowId,
                          FlowCode: params.row.FlowCode,
                          FlowStatus: params.row.FlowStatus,
                          OrderId: params.row.OrderId,
                          OrderCode: params.row.OrderCode,
                          ItemId: params.row.Id,
                          ItemName: params.row.Name
                        });
                      }
                    }
                  },
                  "测试启动准备"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (this.$able.map.BtmsTestItemCancel && params.row.Status != 100) {
              a.push(
                h(
                  "span",
                  {
                    style: {
                      cursor: "pointer",
                      color: "#2d8cf0",
                      display: "inline-block",
                      "vertical-align": "middle"
                    },
                    on: {
                      click: () => {
                        this.toCanel(params.row);
                      }
                    }
                  },
                  "取消项目"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            a.push(
              h(
                "span",
                {
                  style: {
                    cursor: "pointer",
                    color: "#2d8cf0",
                    display: "inline-block",
                    "vertical-align": "middle",
                  },
                  on: {
                    click: () => {
                      this.$refs.subItem.open({
                        OrderId: params.row.OrderId,
                        OrderCode: params.row.OrderCode,
                        ItemId: params.row.Id,
                        ItemName: params.row.Name,
                      });
                    },
                  },
                },
                "子项目"
              )
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.BtmsTestItemUpd
                    ? "md-create"
                    : "md-open",
                  size: 18
                },
                attrs: {
                  title: this.$able.map.BtmsTestItemUpd ? "编辑" : "详情"
                },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.$refs.edit.open(params.row.Id);
                  }
                }
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.BtmsTestItemDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    }
                  }
                })
              );
            }
            return a;
          }
        }
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    // ——————————
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      if (this.$refs.preStart.flag) {
        this.$refs.preStart.close();
      }
      if (this.$refs.dataAndProcess.flag) {
        this.$refs.dataAndProcess.close();
      }
      if (this.$refs.outlineChange.flag) {
        this.$refs.outlineChange.close();
      }
      if (window.toTabData) {
        if (window.toTabData.FlowCode == "ITEM-PRESTART-SIGN") {
          this.searchData1 = {
            OrderId: window.toTabData.OrderId,
            OrderCode: window.toTabData.OrderCode
          };
          this.pageIndex = 1;
          this.onSearch();
          this.$refs.preStart.open({
            FlowId: window.toTabData.FlowId,
            FlowCode: window.toTabData.FlowCode,
            FlowStatus: window.toTabData.FlowStatus,
            OrderId: window.toTabData.OrderId,
            OrderCode: window.toTabData.OrderCode,
            ItemId: window.toTabData.ItemId,
            ItemName: window.toTabData.ItemName
          });
          window.toTabData = null;
        } else if (window.toTabData.FlowCode == "ITEM-TESTOVER-SIGN") {
          this.searchData1 = {
            OrderId: window.toTabData.OrderId,
            OrderCode: window.toTabData.OrderCode
          };
          this.pageIndex = 1;
          this.onSearch();
          this.$refs.dataAndProcess.open({
            OrderId: window.toTabData.OrderId,
            ItemId: window.toTabData.ItemId,
            ItemName: window.toTabData.ItemName
          });
          window.toTabData = null;
        } else if (window.toTabData.FlowCode == "TESTING-SYLLABUS-CHGING") {
          this.searchData1 = {
            OrderId: window.toTabData.OrderId,
            OrderCode: window.toTabData.OrderCode
          };
          this.pageIndex = 1;
          this.onSearch();
          this.$refs.outlineChange.open({
            FlowId: window.toTabData.FlowId,
            FlowCode: window.toTabData.FlowCode,
            OrderId: window.toTabData.OrderId,
            OrderCode: window.toTabData.OrderCode,
            ItemId: window.toTabData.ItemId,
            ItemName: window.toTabData.ItemName
          });
          window.toTabData = null;
        } else {
          window.toTabData = null;
          this.initSearch();
        }
      } else if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code
        };
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
      this.reqLab();
    },
    // 初始化搜索
    initSearch() {
      this.reSearchData();
      this.pageIndex = 1;
      this.onSearch();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    onSearch(action) {
      let data = this.$util.copy(this.searchData1);
      delete data.daterange;
      if (this.searchData1.daterange) {
        if (
          this.searchData1.daterange[0] &&
          typeof this.searchData1.daterange[0] == "object"
        ) {
          data.BillDate = this.searchData1.daterange[0].format("yyyy-MM-dd");
        }
        if (
          this.searchData1.daterange[1] &&
          typeof this.searchData1.daterange[1] == "object"
        ) {
          data.BillDateEnd = this.searchData1.daterange[1].format("yyyy-MM-dd");
        }
      }
      if (action == "export") {
        window.open(
          "/Export/Orderitems" + encodeURI(this.$util.obj2params(data))
        );
      } else {
        this.searchData2 = data;
        this.pageIndex = 1;
        this.reqTable();
      }
    },
    reqTable() {
      if (this.$able.map.BtmsTestItemQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          ParentId: 0
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsOrderItems/QueryPg", { params: params })
        .then(res => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            let lst = res.data.DataSet || [];
            // for (let a of lst) {
            //   if (a.Status >= 2) {
            //     a._expanded = true
            //   } else {
            //     a._disableExpand = true
            //   }
            // }
            this.tableData = lst;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch(err => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode }
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
    },

    // 工程师
    toPickEngr() {
      if (this.searchData1.EngrId) {
        this.$refs.PickUser.open([
          { Id: this.searchData1.EngrId, UserName: this.searchData1.EngrName },
        ]);
      } else {
        this.$refs.PickUser.open();
      }
    },
    onPickEngr(lst) {
      if (lst[0]) {
        this.searchData1.EngrId = lst[0].Id;
        this.$set(this.searchData1, "EngrName", lst[0].UserName);
      }
    },
    unPickEngr() {
      this.searchData1.EngrId = "";
      this.$set(this.searchData1, "EngrName", "");
    },
    // ==========
    toAdd() {
      this.$refs.edit.open();
    },
    toAddSub(parent) {
      this.$refs.edit.open(0, parent);
    },
    toUpdSub(parent, id) {
      this.$refs.edit.open(id, parent);
    },
    onSub() {
      this.$refs.subItem.req();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        }
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsOrderItems/Delete", { Id: id })
        .then(res => {
          if (res.result) {
            this.$Message.success("删除成功");
            if (this.$refs.subItem.flag) {
              this.$refs.subItem.req();
            } else {
              this.reqTable();
            }
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch(err => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toCanel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否取消：" + this.$util.color(row.Name),
        onOk: () => {
          this.doCancel(row.Id);
        },
      });
    },
    doCancel(id) {
      this.$axios
        .post("/Api/BtmsOrderItems/Cancel", { ItemId: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("取消成功");
            if (this.$refs.subItem.flag) {
              this.$refs.subItem.req();
            } else {
              this.reqTable();
            }
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>
<style lang="less">
#OrderItems {
  .bk-page-search .btns .ivu-form-item-content {
    margin: 0 !important;
  }

  td.ivu-table-expanded-cell {
    padding: 0 10px;
  }
}
</style>