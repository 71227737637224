<template>
  <div id="TestItemLastData" class="tabPane-last-data">
    <div class="list-section">
      <div style="padding-top: 10px"></div>
      <Alert type="error" v-if="flow.Status == 99" style="margin-bottom: 20px">
        上次撤消测试终止确认原因：{{ flow.SignOpinionB }}<br />
        撤消操作员：{{ flow.SignUserNameB }}/{{ flow.SignTimeB }}
      </Alert>
      <Alert
        type="warning"
        v-if="(flow.Status == 1 || flow.Status == 2) && flow.Tips"
        >{{ flow.Tips }}</Alert
      >
      <div class="signed-info" v-if="flow.Status == 2 && flow.PartyaAction == 1">
        <span> 委托方： {{ flow.SignUserNameA }} / {{ flow.SignTimeA }}</span>
        <img @click="viewSignImg" :src="flow.SignImgA" alt="" />
      </div>
      <div class="flex">
        <Button
          type="info"
          @click="toAdd"
          v-if="flow.Status != 1 && flow.Status != 2 && $able.map.BtmsTestItemOverSign"
          style="margin: 50px auto"
          >发起测试终止确认</Button
        >
        <Button
          icon="md-refresh"
          size="small"
          :disabled="loading"
          @click="reqLst"
          v-show="flow.Status == 1 || flow.Status == 2"
        ></Button>
        <Button
          type="primary"
          size="small"
          style="margin-left: 10px"
          @click="toAdd"
          v-show="flow.Status == 1"
          ><Icon type="md-cloud-upload" /> 上传数据</Button
        >
        <Button
          type="error"
          size="small"
          style="margin-left: 10px"
          v-if="flow.Status == 1"
          @click="toCancel"
          >撤销终止确认</Button
        >
      </div>

      <div
        class="text-off"
        style="margin: 10px"
        v-if="flow.Status == 1 || flow.Status == 2"
        v-show="!loading && lst.length == 0"
      >
        暂无数据
      </div>

      <div class="bk-loading-line" style="margin: 10px" v-if="loading">
        <Icon type="ios-loading"></Icon>
        <span>Loading</span>
      </div>

      <div class="data-warp" v-for="(a, i) in lst" :key="`data-2-${i}`">
        <Icon
          type="md-close"
          @click="toDel(a.Id)"
          v-if="flow.Status == 1"
        ></Icon>
        <div class="text-off">{{ a.CreateUserName }} / {{ a.CreateTime }}</div>
        <div class="desc">{{ a.Description }}</div>
        <ul>
          <li v-for="(b, j) in a.Attas" :key="`data-${i}-${j}`">
            <a :href="b.Url" target="_blank">{{ b.Name }}</a
            ><span class="text-off">({{ b.Size_ }})</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-section" v-if="flow.Status == 1 || flow.Status == 2">
      <Page
        :total="all"
        :current="pi"
        :page-size="ps"
        transfer
        show-total
        show-sizer
        show-elevator
        @on-change="(index) => onChangePage(index, 0)"
        @on-page-size-change="(size) => onChangePage(1, size)"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    OrderId: [Number],
    ItemId: [Number],
    flow: [Object],
  },
  data() {
    return {
      type: 2,
      loading: false,
      lst: [],
      pi: 1,
      ps: 10,
      all: 0,
    };
  },
  methods: {
    reqLst() {
      this.lst = [];
      if (!this.flow) {
        return;
      } else if (this.flow.Status != 1 && this.flow.Status != 2) {
        return;
      }
      this.loading = true;
      this.$axios
        .get("/Api/BtmsTestData/QueryPg", {
          params: {
            OrderId: this.OrderId,
            ItemId: this.ItemId,
            PageIndex: this.pi,
            PageSize: this.ps,
            Type: this.type,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            let a = [];
            for (let x of res.data.DataSet) {
              for (let y of x.Attas) {
                y.Size_ = this.$util.fileSizeFormat(y.Size * 1024);
              }
              a.push(x);
            }
            this.lst = a;
            this.all = res.data.RecordCount;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    toAdd() {
      this.$emit("to-add", this.type);
    },
    toDel(id) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除",
        onOk: () => {
          this.doDel(id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsTestData/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqLst();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toCancel() {
      this.$emit("to-cancel");
    },
    viewSignImg() {
      this.$root.$emit("view-imgs", [this.flow.SignImgA]);
    },
  },
};
</script>
<style lang="less">
.tabPane-last-data {
  height: 100%;
  .list-section {
    height: calc(~"100% - 50px");
    overflow-y: scroll;
    padding: 0 10px;
  }
  .footer-section {
    display: flex;
    height: 50px;
    padding: 0 10px;
    align-items: center;
    border-top: 1px solid #dcdee2;
  }
  .signed-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    img {
      width: 60px;
      height: 30px;
      border-radius: 4px;
      margin-left: 5px;
      cursor: pointer;
    }
  }
  .data-warp .desc {
    margin-top: 5px;
    word-break: break-all;
  }
}
</style>