<template>
  <div class="push-pre-start">
    <div class="bk-drawer-body">
      <Alert style="padding: 8px">提示：上传样品安装接线及工步设置信息让客户查看并签字确认</Alert>
      <Form @submit.native.prevent>
        <FormItem label="样品安装接线：">
          <div class="attas">
            <input
              @change="onFiles1"
              accept="image/*"
              multiple
              ref="fileInput1"
              style="display: none"
              type="file"
            />
            <div
              @click="
                () => {
                  $refs.fileInput1.click();
                }
              "
              class="bk-add-file"
              style="margin-right: 10px"
            >
              <Icon color="#808695" size="20" type="md-add"></Icon>
            </div>
            <div :key="`img-${i}`" class="atta" v-for="(img, i) in imgs1">
              <img :src="img" @click="viewImgs1(i)" alt />
              <Icon
                @click="delFile1(i)"
                class="icon-remove"
                color="#ed4014"
                size="20"
                type="md-close-circle"
              />
            </div>
          </div>
        </FormItem>
        <FormItem label="工步设置:">
          <div class="attas">
            <input
              @change="onFiles2"
              accept="image/*"
              multiple
              ref="fileInput2"
              style="display: none"
              type="file"
            />
            <div
              @click="
                () => {
                  $refs.fileInput2.click();
                }
              "
              class="bk-add-file"
              style="margin-right: 10px"
            >
              <Icon color="#808695" size="20" type="md-add"></Icon>
            </div>
            <div :key="`img-${i}`" class="atta" v-for="(img, i) in imgs2">
              <img :src="img" @click="viewImgs2(i)" alt />
              <Icon
                @click="delFile2(i)"
                class="icon-remove"
                color="#ed4014"
                size="20"
                type="md-close-circle"
              />
            </div>
          </div>
        </FormItem>
        <FormItem label="给客户留言：">
          <Input type="textarea" v-model="formd.Message" />
        </FormItem>
        <FormItem label="需要客户签字确认：">
          <RadioGroup v-model="formd.AutoCheck">
            <Radio label="0">是</Radio>
            <Radio label="1">否</Radio>
          </RadioGroup>
        </FormItem>
        <div class="text-error">注意：选择“是”时，则需要客户在微信端系统上签字确认才能启动测试</div>
      </Form>
    </div>
    <div class="bk-drawer-footer">
      <Button
        :loading="posting"
        @click="ok"
        type="info"
        v-if="$able.map.BtmsTestItemPreStart"
      >发起测试启动确认流程</Button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ItemId: Number,
  },
  data() {
    return {
      files1: [],
      imgs1: [],
      files2: [],
      imgs2: [],
      formd: {},
      posting: false,
    };
  },
  methods: {
    clear() {
      this.files1 = [];
      this.imgs1 = [];
      this.files2 = [];
      this.imgs2 = [];
      this.formd = {};
      this.posting = false;
    },
    ok() {
      if (this.formd.AutoCheck == undefined) {
        this.$Message.error("请选择：“需要客户签字确认”")
        return
      }
      this.posting = true;
      let formdata = new FormData();
      formdata.append("ItemId", this.ItemId);
      formdata.append("AutoCheck", this.formd.AutoCheck);
      formdata.append("Message", this.formd.Message || "");
      // 接线
      for (let a of this.files1) {
        formdata.append("WireSplice", a);
      }
      // 工步
      for (let b of this.files2) {
        formdata.append("ProcessStep", b);
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/BtmsOrderEx/PushPreStart", formdata, config)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (res.data > 0) {
              this.$Message.success("发起成功");
              this.$emit("on-push", res.data);
              this.clear();
            } else {
              this.$Message.error("发起失败");
            }
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    onFiles1(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.imgs1.push(this.$util.file2url(e.target.files[i]));
        this.files1.push(e.target.files[i]);
      }
      e.target.value = "";
    },
    delFile1(i) {
      this.files1.splice(i, 1);
      this.imgs1.splice(i, 1);
    },
    viewImgs1(i) {
      this.$root.$emit("view-imgs", this.imgs1, i);
    },
    onFiles2(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.imgs2.push(this.$util.file2url(e.target.files[i]));
        this.files2.push(e.target.files[i]);
      }
      e.target.value = "";
    },
    delFile2(i) {
      this.files2.splice(i, 1);
      this.imgs2.splice(i, 1);
    },
    viewImgs2(i) {
      this.$root.$emit("view-imgs", this.imgs2, i);
    },
  },
};
</script>
<style lang="less">
.push-pre-start {
  .ivu-form-item {
    margin-bottom: 10px;
  }
  .attas {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
}
</style>