<template>
  <div>
    <Drawer
      class="bk-sub-drawer data-and-process-drawer"
      v-model="flag"
      :transfer="false"
      :mask-closable="false"
      width="500"
      @on-close="close"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="reqFlow">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="drawer-item-name">项目：{{ ItemName }}</div>
      <Tabs :value="tabIndex" :animated="false">
        <TabPane label="测试终止确认" name="0">
          <last-data
            :OrderId="OrderId"
            :ItemId="ItemId"
            :flow="flow"
            ref="lastData"
            @to-add="toAddTestData"
            @to-cancel="toCancelTestOver"
          ></last-data>
        </TabPane>
        <TabPane label="测试数据" name="1">
          <test-data
            :OrderId="OrderId"
            :ItemId="ItemId"
            :flow="flow"
            ref="testData"
            @to-add="toAddTestData"
          ></test-data>
        </TabPane>
        <TabPane label="测试进度" name="2">
          <test-process
            :OrderId="OrderId"
            :ItemId="ItemId"
            :flow="flow"
            ref="testProcess"
            @to-add="toAddProcess"
          ></test-process>
        </TabPane>
      </Tabs>
    </Drawer>
    <test-data-add
      :OrderId="OrderId"
      :ItemId="ItemId"
      :flow="flow"
      ref="testDataAdd"
      @on-post="onAddTestData"
    ></test-data-add>
    <test-process-add
      :OrderId="OrderId"
      :ItemId="ItemId"
      :ItemName="ItemName"
      ref="testProcessAdd"
      @on-post="onAddProcess"
    ></test-process-add>
    <cancel-test-over
      ref="CancelTestOver"
      @on-post="onCancelTestOver"
    ></cancel-test-over>
  </div>
</template>
<script>
import lastData from "./tabPane-last-data.vue";
import testData from "./tabPane-test-data.vue";
import testProcess from "./tabPane-process.vue";
import testProcessAdd from "./test-process-add.vue";
import testDataAdd from "./test-data-add.vue";
import CancelTestOver from "../../../components/CancelTestOver.vue";
export default {
  components: {
    lastData,
    testData,
    testProcess,
    testProcessAdd,
    testDataAdd,
    CancelTestOver,
  },
  data() {
    return {
      OrderId: 0,
      ItemId: 0,
      ItemName: "",
      flow: {},
      flag: false,
      tabIndex: "0",
      loading: false,
      failed: false,
    };
  },
  methods: {
    open(data) {
      this.tabIndex = "2"
      this.OrderId = data.OrderId;
      this.ItemId = data.ItemId;
      this.ItemName = data.ItemName;
      this.flag = true;
      this.$nextTick(() => {
        this.reqFlow();
      });
    },
    close() {
      this.flag = false;
      this.$refs.lastData.pi = 1;
      this.$refs.testData.pi = 1;
      this.$refs.testProcess.pi = 1;
    },
    reqFlow() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsFlow/GetLast", {
          params: {
            OrderId: this.OrderId,
            ItemId: this.ItemId,
            FlowCode: "ITEM-TESTOVER-SIGN",
            OnlyValid: false,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.failed = false;
            this.flow = res.data || {};
            this.$nextTick(() => {
              this.$refs.lastData.reqLst();
              this.$refs.testData.reqLst();
              this.$refs.testProcess.reqLst();
            });
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    toAddTestData(type) {
      this.$refs.testDataAdd.open(type);
    },
    onAddTestData(type) {
      if (type == 2) {
        if (this.flow.Status != 1) {
          this.reqFlow();
          this.$emit("on-flow");
        } else {
          this.$refs.lastData.reqLst();
        }
      } else if (type == 1) {
        this.$refs.testData.reqLst();
      }
    },
    toAddProcess(id) {
      this.$refs.testProcessAdd.open(id);
    },
    onAddProcess() {
      this.$refs.testProcess.reqLst();
    },
    toCancelTestOver() {
      this.$refs.CancelTestOver.open(this.ItemId);
    },
    onCancelTestOver() {
      this.reqFlow();
    },
  },
};
</script>
<style lang="less">
.data-and-process-drawer {
  .ivu-drawer-body {
    height: 100%;
    padding: 0;
  }
  .drawer-item-name {
    position: absolute;
    top: 8px;
    left: 15px;
    font-size: 12px;
    font-weight: bold;
    color: #2b85e4;
  }
  .ivu-tabs {
    height: 100%;
  }
  .ivu-tabs-nav-scroll {
    padding-top: 24px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08) inset;
  }
  .ivu-tabs-bar {
    margin-bottom: 0;
  }
  .ivu-tabs-content {
    height: calc(~"100% - 60px");
  }
  .ivu-tabs-tabpane {
    height: 100%;
  }
  .data-warp {
    padding: 10px;
    margin: 10px 0;
    background: #f8f8f9;
    position: relative;
    li {
      padding: 5px 0;
      list-style: none;
      border-bottom: 1px dashed #dcdee2;
    }
    li:last-child {
      border-bottom: 0;
    }
    .ivu-icon-md-close {
      position: absolute;
      cursor: pointer;
      font-size: 20px;
      top: 5px;
      right: 5px;
    }
    .ivu-icon-md-close:hover {
      color: #ed4014;
    }
  }
}
</style>