<template>
  <div id="preStart">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer pre-start-drawer"
      title
      v-model="flag"
      width="900"
    >
      <div class="ivu-drawer-header-inner" slot="header">
        <span>测试启动准备</span>
        <Divider type="vertical" />
        <span class="text-sub">{{ OrderCode }}</span>
        <Divider type="vertical" />
        <span class="text-sub">{{ ItemName }}</span>
      </div>
      <div class="flex" style="height: 100%">
        <div class="pre-start-left">
          <related-res :itemid="ItemId" :ordercode="OrderCode" :orderid="OrderId" ref="relatedRes"></related-res>
        </div>
        <div class="pre-start-right">
          <Spin class="bk-loading" fix v-if="flowLoading">
            <Icon type="ios-loading"></Icon>
            <div>Loading</div>
          </Spin>
          <Spin class="bk-failed" fix v-if="flowFailed">
            <Icon @click="reqFlow" type="md-refresh"></Icon>
            <div>Failed</div>
          </Spin>
          <push-pre-start
            :ItemId="ItemId"
            @on-push="onFlow"
            ref="pushPreStart"
            v-if="pushAble && !flow"
          />
          <pushed-pre-start :flow="flow" @to-start="toStart" ref="pushedPreStart" v-if="flow" />
        </div>
      </div>
    </Drawer>
    <start @on-flow="onFlow" ref="start" />
  </div>
</template>
<script>
import relatedRes from "./related-res.vue";
import pushPreStart from "./push-pre-start.vue";
import PushedPreStart from "./pushed-pre-start.vue";
import start from "./start.vue";
export default {
  components: { relatedRes, pushPreStart, PushedPreStart, start },
  data() {
    return {
      OrderId: 0,
      OrderCode: "",
      ItemId: 0,
      ItemName: "",
      FlowId: "",
      FlowCode: "",
      FlowStatus: "",
      // ——————————
      pushAble: false,
      // ——————————
      flag: false,
      flowLoading: false,
      flowFailed: false,
      flow: "",
    };
  },
  methods: {
    open(data) {
      this.flag = true;
      this.FlowId = data.FlowId;
      this.FlowCode = data.FlowCode;
      this.FlowStatus = data.FlowStatus;
      this.OrderId = data.OrderId;
      this.OrderCode = data.OrderCode;
      this.ItemId = data.ItemId;
      this.ItemName = data.ItemName;
      this.$nextTick(() => {
        this.$refs.relatedRes.req();
      });

      // 测试大纲已确认
      let flag3 =
        data.FlowCode == "TESTING-SYLLABUS-SIGN" && data.FlowStatus == 2;
      let flag1 = data.FlowCode == "BOM-SIGN";
      let flag2 =
        data.FlowCode == "TESTING-SYLLABUS-SIGN-AGAIN" && data.FlowStatus == 2;
      if ((flag3 || flag1 || flag2) && this.$able.map.BtmsTestItemPreStart) {
        // 大纲已确认 或 大纲已重新确认
        // 需重新测试启动准备
        this.pushAble = true;
      } else {
        this.pushAble = false;
        if (data.FlowCode == "ITEM-PRESTART-SIGN") {
          // 已发起测试启动准备
          this.reqFlow({
            FlowId: data.FlowId,
            OrderId: data.OrderId,
          });
        } else {
          /**
           * 可能：
           * 测试完成：ITEM-TESTOVER-SIGN
           * 测试大纲变更：TESTING-SYLLABUS-CHGING
           */
          this.reqFlow({
            FlowCode: "ITEM-PRESTART-SIGN",
            OrderId: data.OrderId,
            ItemId: data.ItemId,
          });
        }
      }
    },
    close() {
      this.flag = false;
      this.flowLoading = false;
      this.flowFailed = false;
      this.FlowId = 0;
      this.FlowCode = "";
      this.FlowStatus = "";
      this.OrderId = 0;
      this.OrderCode = "";
      this.ItemId = 0;
      this.ItemName = "";
      this.pushAble = false;
      this.flow = "";
    },
    reqFlow(params) {
      this.flowLoading = true;
      this.flowFailed = false;
      this.$axios
        .get("/Api/BtmsFlow/GetLast", {
          params: params,
        })
        .then((res) => {
          this.flowLoading = false;
          if (res.result) {
            this.flowFailed = false;
            this.flow = res.data;
          } else {
            this.flowFailed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.flowLoading = false;
          this.flowFailed = true;
          this.$Message.error(err.errormsg);
        });
    },
    onFlow() {
      this.pushAble = false;
      this.flow = "";
      this.reqFlow({
        FlowCode: "ITEM-PRESTART-SIGN",
        OrderId: this.OrderId,
        ItemId: this.ItemId,
      });
      this.$emit("on-flow");
    },
    toStart() {
      this.$refs.start.open(this.ItemId);
    },
  },
};
</script>
<style lang="less">
.pre-start-drawer {
  .ivu-drawer-close {
    z-index: 9;
  }
  .ivu-drawer-body {
    padding: 0;
  }
  .pre-start-right {
    width: 450px;
    height: 100%;
    position: relative;
  }
  .pre-start-left {
    width: 450px;
    height: 100%;
    border-right: 1px solid #dcdee2;
    padding: 10px 15px 10px 15px;
  }
}
</style>