<template>
  <div class="tabPane-test-data">
    <div class="list-section">
      <div class="flex" style="padding: 10px 0 0">
        <Button :disabled="loading" @click="reqLst" icon="md-refresh" size="small"></Button>
        <Button
          @click="toAdd"
          size="small"
          style="margin-left: 10px"
          type="primary"
          v-if="flow.Status != 2 && $able.map.BtmsTestItemUploadTestData"
        >
          <Icon type="md-cloud-upload" />上传数据
        </Button>
        <!-- <Button
          size="small"
          style="margin-left: 10px"
          type="error"
          v-if="flow.Status == 3 && $able.map.BtmsTestItemStop"
        >暂停测试</Button> -->
      </div>

      <div class="text-off" style="margin: 10px" v-if="!loading && lst.length == 0">暂无数据</div>

      <div class="bk-loading-line" style="margin: 10px" v-if="loading">
        <Icon type="ios-loading"></Icon>
        <span>Loading</span>
      </div>

      <div :key="`data-2-${i}`" class="data-warp" v-for="(a, i) in lst">
        <Icon @click="toDel(a.Id)" type="md-close" v-if="flow.Status != 2"></Icon>
        <div class="text-sub flex items-center" v-if="a.SignUserId">
          客户-{{ a.SignUserName }} / {{ a.SignTime }}
          <img
            :src="a.SignImg"
            @click="viewSignImg(i)"
            alt
            class="sign-img"
          />
        </div>
        <div class="text-sub">我方-{{ a.CreateUserName }} / {{ a.CreateTime }}</div>
        <div class="desc">{{ a.Description }}</div>
        <ul>
          <li :key="`data-${i}-${j}`" v-for="(b, j) in a.Attas">
            <a :href="b.Url" target="_blank">{{ b.Name }}</a>
            <span class="text-off">({{ b.Size_ }})</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer-section">
      <Page
        :current="pi"
        :page-size="ps"
        :total="all"
        @on-change="(index) => onChangePage(index, 0)"
        @on-page-size-change="(size) => onChangePage(1, size)"
        show-elevator
        show-sizer
        show-total
        transfer
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    OrderId: [Number],
    ItemId: [Number],
    flow: [Object],
  },
  data() {
    return {
      type: 1,
      loading: false,
      lst: [],
      pi: 1,
      ps: 10,
      all: 0,
    };
  },
  methods: {
    reqLst() {
      this.lst = [];
      this.loading = true;
      this.$axios
        .get("/Api/BtmsTestData/QueryPg", {
          params: {
            OrderId: this.OrderId,
            ItemId: this.ItemId,
            PageIndex: this.pi,
            PageSize: this.ps,
            Type: this.type,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            let a = [];
            for (let x of res.data.DataSet) {
              for (let y of x.Attas) {
                y.Size_ = this.$util.fileSizeFormat(y.Size * 1024);
              }
              a.push(x);
            }
            this.lst = a;
            this.all = res.data.RecordCount;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    toAdd() {
      this.$emit("to-add", this.type);
    },
    toDel(id) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除",
        onOk: () => {
          this.doDel(id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsTestData/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqLst();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    viewSignImg(i) {
      this.$root.$emit("view-imgs", [this.lst[i].SignImg]);
    },
  },
};
</script>
<style lang="less">
.tabPane-test-data {
  height: 100%;
  .list-section {
    height: calc(~"100% - 50px");
    overflow-y: scroll;
    padding: 0 10px;
  }
  .footer-section {
    display: flex;
    height: 50px;
    padding: 0 10px;
    align-items: center;
    border-top: 1px solid #dcdee2;
  }
  .sign-img {
    width: 60px;
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    border: 1px solid #dcdee2;
  }
  .data-warp .desc {
    margin-top: 5px;
    word-break: break-all;
  }
}
</style>