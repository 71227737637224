<template>
  <div>
    <Modal class="testingSyllabus-change" v-model="flag" title="变更测试大纲" :mask-closable="false" :transfer="false" width="700" @on-cancel="close" :styles="{top:'30px'}">
      <Form @submit.native.prevent :model="formd" :rules="rules" ref="form" :label-width="150">
        <FormItem label="测试项目：">
          {{formd.ItemName}}
        </FormItem>
        <FormItem label="测试大纲编制人：" prop="EditorId" required>
          <Input :value="formd.EditorName" @on-clear="unPickEditor" clearable readonly v-if="ABLE">
            <Button @click="toPickEditor" icon="ios-more" slot="append"></Button>
          </Input>
          <div v-else>{{formd.EditorName}}</div>
        </FormItem>
        <FormItem label="变更大纲原因：" prop="Reason" required>
            <Input type="textarea" v-if="ABLE" v-model="formd.Reason" />
            <div v-else>{{formd.Reason}}</div>
        </FormItem>
        <FormItem label="新的测试大纲文件：" required>
          <Upload multiple type="drag" action="/File/PostTestingSyllabusFile" name="file" :data="formd" :show-upload-list="false" :before-upload="handleUpload">
            <div style="padding:20px;">
              <Icon type="md-cloud-upload" size="52" style="color: #3399ff;"></Icon>
              <p class="text-sub">点击 或 拖拽 上传</p>
              <p v-if="file" class="text-primary">{{file.name}}</p>
            </div>
          </Upload>
        </FormItem>
        <FormItem label=" ">
          <div style="color:#ed4014;">提示：系统同时发起测试大纲确认流程，客户可在公众号端查看并签字确认</div>
        </FormItem>
      </Form>

      <div slot="footer">
        <Button type="primary" :loading="posting" @click="ok">确认提交</Button>
      </div>
    </Modal>
    <pick-user :type="1" @on-ok="onPickEditor" ref="PickUser" title="选择编制人" />
  </div>
</template>
<script>
import PickUser from "../../components/PickUser.vue";
export default {
  components: { PickUser },
  data() {
    return {
      ABLE: false,
      flag: false,
      posting: false,
      formd: {},
      rules: {
        EditorId: [{ required: true, type: "number", message: " " }],
        Reason: [{ required: true, message: " " }],
      },
      file: "",
    };
  },
  methods: {
    open(data) {
      this.ABLE = this.$able.map.BtmsTestItemSyllabusChg;
      this.flag = true;
      this.formd.ItemId = data.ItemId;
      this.formd.ItemName = data.ItemName;
    },
    close() {
      this.ABLE = false
      this.flag = false;
      this.posting = false;
      this.formd = {}
      this.$refs.form.resetFields();
      this.file = ""
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.file) {
            this.$Message.warning("请选择文件")
          } else {
            this.postData();
          }
        }
      });
    },
    // 编制人
    toPickEditor() {
      if (this.formd.EditorId) {
        this.$refs.PickUser.open([
          { Id: this.formd.EditorId, UserName: this.formd.EditorName },
        ]);
      } else {
        this.$refs.PickUser.open();
      }
    },
    onPickEditor(lst) {
      if (lst[0]) {
        this.formd.EditorId = lst[0].Id;
        this.$set(this.formd, "EditorName", lst[0].UserName);
      }
    },
    unPickEditor() {
      this.formd.EditorId = "";
      this.$set(this.formd, "EditorName", "");
    },
    handleUpload(file) {
      this.file = file;
      return false;
    },
    postData() {
      let formdata = new FormData();
      formdata.append("ItemId", this.formd.ItemId);
      formdata.append("EditorId", this.formd.EditorId);
      formdata.append("Reason", this.formd.Reason);
      formdata.append("File", this.file);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.posting = true;
      this.$axios
        .post("/BtmsOrderEx/TestingSyllabusChgSign", formdata, config)
        .then(res => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("变更成功");
            this.$emit("on-flow");
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch(err => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  }
};
</script>

