<template>
  <Modal
    :styles="{top:'50px'}"
    @on-cancel="close"
    class="test-process-add-modal"
    title="发布消息"
    v-model="flag"
    width="500"
  >
    <Spin class="bk-loading" fix v-if="loading">
      <div>
        <Icon type="ios-loading"></Icon>
        <div>Loading</div>
      </div>
    </Spin>
    <Spin class="bk-failed" fix v-if="failed">
      <div @click="reqSubItems">
        <Icon type="md-refresh"></Icon>
        <p>Refresh</p>
      </div>
    </Spin>
    <Form :model="formd" :rules="rule" @submit.native.prevent ref="form">
      <FormItem
        :style="`max-height:${subItems_h}px;overflow-y:scroll;`"
        style="margin-bottom: 5px"
        v-show="subItems.length>0"
      >
        <span
          :class="formd.SubItemId == a.Id ? 'sub-item pointer text-primary' : 'sub-item pointer text-sub'"
          :key="i"
          @click="onSubItem(a.Id)"
          v-for="(a, i) in subItems"
        >#{{a.Name}}</span>
      </FormItem>
      <FormItem prop="Content" style="margin: 0">
        <Input :rows="5" placeholder="请输入内容" type="textarea" v-model="formd.Content" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button :loading="posting" @click="ok" type="primary">确定</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    OrderId: Number,
    ItemId: Number,
    ItemName: String,
  },
  data() {
    return {
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      formd: {},
      rule: { Content: [{ required: true, message: " " }] },
      subItems: [],
      subItems_ok: false,
      subItems_h: 200,
    };
  },
  methods: {
    open(TraceId) {
      this.subItems_h = this.$root.$el.clientHeight - 350;
      this.flag = true;
      this.formd.TraceId = TraceId || 0;
      this.reqSubItems();
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = {};
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          formdata.append("OrderId", this.OrderId);
          formdata.append("DataId", this.ItemId);
          formdata.append("ItemId", this.ItemId);
          if (this.formd.SubItemId) {
            formdata.append("SubItemId", this.formd.SubItemId);
          }
          formdata.append("FlowCode", "TESTING-LOG");
          formdata.append("TraceId", this.formd.TraceId);
          formdata.append("Content", this.formd.Content);
          let config = { headers: { "Content-Type": "multipart/form-data" } };
          this.posting = true;
          this.$axios
            .post("/BtmsOrderEx/AddFlowTrace2", formdata, config)
            .then((res) => {
              this.posting = false;
              if (res.result) {
                this.$Message.success("发布成功");
                this.close();
                this.$emit("on-post");
              } else {
                this.$Message.error(res.errormsg);
              }
            })
            .catch((err) => {
              this.posting = false;
              this.$Message.error(err.errormsg);
            });
        }
      });
    },
    reqSubItems() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsOrderItems/QueryByOrder", {
          params: {
            OrderId: this.OrderId,
            ParentId: this.ItemId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.subItems_ok = true;
            this.subItems = res.data;
          } else if (res.errormsg) {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onSubItem(id) {
      this.formd.SubItemId == id
        ? this.$set(this.formd, "SubItemId", "")
        : this.$set(this.formd, "SubItemId", id);
    },
  },
};
</script>
<style lang="less">
.test-process-add-modal {
  .sub-item {
    text-decoration: underline;
    margin-right: 10px;
  }
}
</style>