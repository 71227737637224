<template>
  <div class="related-res-warp">
    <Spin class="bk-loading" fix v-if="loading">
      <div>
        <Icon type="ios-loading"></Icon>
        <div>Loading</div>
      </div>
    </Spin>
    <Spin class="bk-failed" fix v-if="failed">
      <div @click="req">
        <Icon type="md-refresh"></Icon>
        <p>Refresh</p>
      </div>
    </Spin>
    <div class="related-res-box">
      <span class="entrance-text" @click="toStockOut"
        ><Icon type="md-add" />样品&物料申领</span
      >
      <Divider type="vertical" />
      <span class="entrance-text" @click="toStockIn">检毕入库</span>
      <div class="text-error">
        提示：领样申请提交后由样品管理员审核通过才能出库
      </div>
      <div>申领的样品：</div>
      <ul>
        <li v-for="(a, i) in goods1" :key="`goods1-${i}`">
          <span>{{ a.GoodsName }}</span>
          <span v-if="a.GoodsNo">({{ a.GoodsNo }})</span>
          <span> * </span>
          <span>{{ a.Qty }}</span>
          <span>({{$designConst.stockOut.Status[a.Status]}})</span>
        </li>
      </ul>
      <div>申领的物料：</div>
      <ul>
        <li v-for="(a, i) in goods2" :key="`goods2-${i}`">
          <span>{{ a.GoodsName }}</span>
          <span v-if="a.GoodsNo">({{ a.GoodsNo }})</span>
          <span> * </span>
          <span>{{ a.Qty }}</span>
          <span>({{$designConst.stockOut.Status[a.Status]}})</span>
        </li>
      </ul>
    </div>
    <div class="related-res-box">
      <span class="entrance-text" @click="toUseTrace"
        ><Icon type="md-add" />仪器使用申请</span
      >
      <div class="text-error">提示：申请提交后由仪器管理员审核通过才能使用</div>
      <div>申请的仪器：</div>
      <ul>
        <li v-for="(a, i) in equs" :key="`equ-${i}`">
          <span>{{ a.EquipmentName }}</span>
          <span>({{ $designConst.equipmentUseTrace.Status[a.Status] }})</span>
          <span>{{ a.Qty }}</span>
          <template v-if="a.Status==4">
          <Divider type="vertical" />
          <span class="entrance-text" @click="toUseEnd(a)">使用结束</span>
          </template>
          <div class="text-sub text-xs">{{ a.UseDescription }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orderid: [String, Number],
    ordercode: [String],
    itemid: [String, Number],
  },
  data() {
    return {
      loading: false,
      failed: false,
      goods1: [],
      goods2: [],
      equs: [],
    };
  },
  methods: {
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsOrderItems/QueryUseGoodsAndEqu", {
          params: { Id: this.itemid },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            let goods1 = [],
              goods2 = [];
            for (let a of res.data.Goods) {
              if (a.SubType == 1) {
                goods1.push(a);
              } else if (a.SubType == 99) {
                goods2.push(a);
              }
            }
            this.goods1 = goods1;
            this.goods2 = goods2;
            this.equs = res.data.Equs;
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    toStockOut() {
      this.$tab.open(this, "stockOut", { go: 1, re: 1 });
      window.toTabData = {
        action: "toStockOut",
        OrderId: this.orderid,
        OrderCode: this.ordercode,
      };
    },
    toStockIn() {
      this.$tab.open(this, "stockIn", { go: 1, re: 1 });
      window.toTabData = {
        action: "toStockInAfterTest",
        OrderId: this.orderid,
        OrderCode: this.ordercode,
        ItemId: this.itemid,
      };
    },
    toUseTrace() {
      this.$tab.open(this, "equipmentUseTrace", { go: 1, re: 1 });
      window.toTabData = {
        action: "toEquipmentUseApply",
        OrderId: this.orderid,
        OrderCode: this.ordercode,
        ItemId: this.itemid,
      };
    },
    // 使用结束
    toUseEnd(data) {
      this.$tab.open(this, "equipmentUseTrace", { go: 1, re: 1 });
      window.toTabData = {
        action: "toUseEnd",
        Id: data.Id
      }
    }
  },
};
</script>
<style lang="less" scoped>
.related-res-warp {
  position: relative;
}
.related-res-box {
  margin: 10px 0 0;
  padding: 5px;
  border: 1px dashed #dcdee2;
  .entrance-text {
    color: #2d8cf0;
    cursor: pointer;
    text-decoration: underline;
  }
  .entrance-text:hover {
    color: #5cadff;
  }
  ul {
    padding-left: 20px;
  }
  li {
    font-size: 12px;
  }
}
</style>