<template>
  <div class="tabPane-process">
    <div class="list-section">
      <div class="flex" style="padding: 10px 0 0">
        <Button :disabled="loading" @click="reqLst" icon="md-refresh" size="small"></Button>
        <Button
          @click="toAdd()"
          size="small"
          style="margin-left: 10px"
          type="primary"
          v-if="flow.Status != 2"
        >
          <Icon type="md-add" />提交进度
        </Button>
      </div>

      <div class="text-off" style="margin: 10px" v-if="!loading && lst.length == 0">暂无数据</div>

      <div class="bk-loading-line" style="margin: 10px" v-if="loading">
        <Icon type="ios-loading"></Icon>
        <span>Loading</span>
      </div>

      <div :key="`data-2-${i}`" class="trace-item" v-for="(a, i) in lst">
        <div class="left">
          <div class="name">
            <span class="text-info" v-if="a.Tag">#{{a.Tag}}&nbsp;</span>
            {{ a.Content }}
            <img
              :src="a.SignImg"
              @click="viewSignImg(a.SignImg)"
              class="sign-img"
              v-if="a.SignImg"
            />
          </div>
          <div class="sub-info">
            <span v-if="a.Party == 1">委托方</span>
            <span v-if="a.Party == 2">倍特睿</span>
            <span>- {{ a.CreateUserName }} / {{ a.CreateTime }}</span>
          </div>
          <div :key="`trace-${i}-${j}`" class="right" v-for="(b, j) in a.Replys">
            <div class="name">
              {{ b.Content }}
              <img
                :src="b.SignImg"
                @click="viewSignImg(b.SignImg)"
                class="sign-img"
                v-if="b.SignImg"
              />
            </div>
            <div class="sub-info">
              <span v-if="b.Party == 1">委托方</span>
              <span v-if="b.Party == 2">倍特睿</span>
              <span>- {{ b.CreateUserName }} / {{ b.CreateTime }}</span>
            </div>
          </div>
          <div class="right" v-if="a.Party == 1 && !a.Replys">
            <div @click="toAdd(a.Id)" class="to-comment">回复</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section">
      <Page
        :current="pi"
        :page-size="ps"
        :total="all"
        @on-change="(index) => onChangePage(index, 0)"
        @on-page-size-change="(size) => onChangePage(1, size)"
        show-elevator
        show-sizer
        show-total
        transfer
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    OrderId: [Number],
    ItemId: [Number],
    flow: [Object],
  },
  data() {
    return {
      type: 2,
      loading: false,
      lst: [],
      pi: 1,
      ps: 10,
      all: 0,
    };
  },
  methods: {
    reqLst() {
      this.lst = [];
      this.loading = true;
      this.$axios
        .get("/Api/BtmsFlowTrace/QueryPg", {
          params: {
            FlowCode: "TESTING-LOG",
            OrderId: this.OrderId,
            ItemId: this.ItemId,
            PageIndex: this.pi,
            PageSize: this.ps,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.lst = res.data.DataSet;
            this.all = res.data.RecordCount;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    toAdd(id) {
      this.$emit("to-add", id);
    },
    viewSignImg(img) {
      console.log("viewSignImg", img);
      this.$root.$emit("view-imgs", [img]);
    },
  },
};
</script>
<style lang="less">
.tabPane-process {
  height: 100%;
  .list-section {
    height: calc(~"100% - 50px");
    overflow-y: scroll;
    padding: 0 10px;
  }
  .footer-section {
    display: flex;
    height: 50px;
    padding: 0 10px;
    align-items: center;
    border-top: 1px solid #dcdee2;
  }
}
</style>