<template>
  <Modal
    :title="type == 2 && flow.Status != 1 ? '发起测试终止确认' : '上传数据'"
    @on-cancel="close"
    class="add-test-data-modal"
    v-model="flag"
  >
    <Form :model="formd" :rules="rules" @submit.native.prevent label-position="top" ref="form">
      <FormItem label="客户签字：" prop="AutoCheck" v-show="type == 2 && flow.Status != 1">
        <RadioGroup v-model="formd.AutoCheck">
          <Radio :label="0">需要签字</Radio>
          <Radio :label="1">不需要签字</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="数据文件：">
        <input @change="onFiles" multiple ref="fileInput" style="display: none" type="file" />
        <Button
          @click="
            () => {
              $refs.fileInput.click();
            }
          "
          ghost
          type="primary"
          v-if="$refs.fileInput"
        >
          <Icon type="md-add"></Icon>选择文件
        </Button>
        <ul class="data-files">
          <li :key="i" class="flex justify-between data-file" v-for="(file, i) in files">
            <div class="data-file-name">{{ file.name }}</div>
            <Button @click="deleteFile(i)" class="close" icon="md-close" type="text"></Button>
          </li>
        </ul>
      </FormItem>
      <FormItem label="数据描述：">
        <Input type="textarea" v-model="formd.Description" />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button :loading="posting" @click="ok" type="primary">确定</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    OrderId: Number,
    ItemId: Number,
    flow: Object,
  },
  data() {
    return {
      type: "",
      flag: false,
      posting: false,
      formd: {},
      rules: {
        AutoCheck: [{ required: true, message: " ", type: "number" }],
      },
      files: [],
    };
  },
  methods: {
    open(type) {
      this.flag = true;
      this.type = type;
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.formd = {};
      this.files = [];
      this.$refs.form.resetFields();
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (this.type == 2 && this.flow.Status != 1 && !valid) {
          this.$Message.warning("请决定是否需要客户签字");
          return;
        }
        if (this.files.length > 0 || this.formd.Description) {
          let api = "";
          if (this.type == 2) {
            api =
              this.flow.Status != 1
                ? "/BtmsOrderEx/PushTestOver"
                : "/BtmsOrderEx/AddTestData";
          } else if (this.type == 1) {
            api = "/BtmsOrderEx/AddTestData";
          }
          let formdata = new FormData();
          formdata.append("OrderId", this.OrderId);
          formdata.append("ItemId", this.ItemId);
          formdata.append("Type", this.type);
          if (this.type == 2 && this.flow.Status != 1) {
            formdata.append("AutoCheck", this.formd.AutoCheck);
          }
          formdata.append("Description", this.formd.Description || "");
          for (let a of this.files) {
            if (a.Id == undefined) {
              formdata.append("File", a);
            }
          }
          let config = { headers: { "Content-type": "multipart/form-data" } };
          this.posting = true;
          this.$axios
            .post(api, formdata, config)
            .then((res) => {
              this.posting = false;
              if (res.result) {
                this.$Message.success("保存成功");
                this.close();
                this.$emit("on-post", this.type);
              } else {
                this.$Message.error(res.errormsg);
              }
            })
            .catch((err) => {
              this.posting = false;
              this.$Message.error(err.errormsg);
            });
        } else {
          this.$Message.warning("请选择数据文件或输入数据描述");
        }
      });
    },
    onFiles(e) {
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.files.push(e.target.files[i]);
        }
        e.target.value = "";
      }
    },
    deleteFile(i) {
      this.files.splice(i, 1);
    },
  },
};
</script>
<style lang="less">
.add-test-data-modal {
  .ivu-form-item-error .ivu-radio-inner {
    border: 1px solid #ed4014;
  }
  .bk-add-file {
    width: 100%;
    height: 32px;
  }
  .data-files {
    margin-top: 5px;
  }
  .data-file {
    border-bottom: 1px dashed #dcdee2;
    padding: 5px 0;
  }
  .data-file-name {
    width: calc(~"100% - 42px");
    word-break: break-dataAll;
    line-height: 20px;
    align-self: center;
  }
}
</style>