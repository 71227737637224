<template>
  <div>
    <Modal
      v-model="flag"
      title="测试大纲变更审核"
      :mask-closable="false"
      width="400"
      @on-cancel="close"
    >
      <Form @submit.native.prevent :model="formd" :rules="rules" ref="form">
        <FormItem prop="Opinion" style="margin:0">
          <Input v-model="formd.Opinion" type="textarea" placeholder="意见"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button
          type="error"
          :disabled="posting && formd.Action != 2"
          :loading="posting && formd.Action == 2"
          @click="ok(2)"
          >拒绝</Button
        >
        <Button
          type="success"
          :disabled="posting && formd.Action != 1"
          :loading="posting && formd.Action == 1"
          @click="ok(1)"
          >确定</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      posting: false,
      formd: {},
      rules: {
        Opinion: [{validator:(rule,value,callback)=>{
          if (this.formd.Action == 2) {
            value ? callback() : callback(new Error(" "))
          } else {
            callback()
          }
        }}],
      },
    };
  },
  methods: {
    open(id) {
      this.formd.FlowId = id;
      this.flag = true;
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok(action) {
      this.formd.Action = action
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      this.$axios
        .post("/Api/BtmsOrderItems/TestingSyllabusApplyConfirm", this.formd)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("操作成功");
            this.close();
            this.$emit("on-flow");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>

