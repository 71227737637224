<template>
  <div>
    <Drawer
      class="bk-sub-drawer outline-change-drawer"
      v-model="flag"
      :transfer="false"
      :mask-closable="false"
      width="450"
      @on-close="close"
    >
      <div slot="header" class="ivu-drawer-header-inner">
        <span>测试大纲变更</span>
        <Divider type="vertical" />
        <span class="text-sub">{{ OrderCode }}</span>
        <Divider type="vertical" />
        <span class="text-sub">{{ ItemName }}</span>
      </div>
      <div class="bk-drawer-body">
        <template v-if="flow && flow.Status == 2">
          <div class="sub-titles">审核意见</div>
          <div style="margin: 10px 0 20px">{{ flow.SignOpinionB }}</div>
        </template>
        <template v-if="flow">
          <div class="sub-titles">变更原因</div>
          <div style="margin: 10px 0 20px">{{ flow.SignOpinionA }}</div>
        </template>
        <attach-and-trace ref="attachAndTrace" />
      </div>
      <div class="bk-drawer-footer" v-if="flow && flow.Status == 1 && $able.map.BtmsTestItemSyllabusChg">
        <Button type="info" @click="toConfirm">审核</Button>
      </div>
    </Drawer>

    <confirm-testing-syllabus-apply
      ref="ConfirmTestingSyllabusApply"
      @on-flow="onConfirm"
    />
  </div>
</template>
<script>
import attachAndTrace from "../../../components/attach-and-trace.vue";
import ConfirmTestingSyllabusApply from "../../../components/ConfirmTestingSyllabusApply.vue";
export default {
  components: { attachAndTrace, ConfirmTestingSyllabusApply },
  data() {
    return {
      OrderId: 0,
      OrderCode: "",
      ItemId: 0,
      ItemName: "",
      FlowId: "",
      FlowCode: "",
      // ——————————
      flag: false,
      flowLoading: false,
      flowFailed: false,
      flow: "",
    };
  },
  methods: {
    open(data) {
      this.flag = true;
      this.FlowId = data.FlowId;
      this.FlowCode = data.FlowCode;
      this.OrderId = data.OrderId;
      this.OrderCode = data.OrderCode;
      this.ItemId = data.ItemId;
      this.ItemName = data.ItemName;
      if (data.FlowCode == "TESTING-SYLLABUS-CHGING") {
        this.reqFlow({
          FlowId: data.FlowId,
          OrderId: data.OrderId,
          ItemId: data.ItemId,
        });
      } else {
        this.reqFlow({
          FlowCode: "TESTING-SYLLABUS-CHGING",
          OrderId: data.OrderId,
          ItemId: data.ItemId,
        });
      }
    },
    close() {
      this.flag = false;
      this.flowLoading = false;
      this.flowFailed = false;
      this.FlowId = 0;
      this.FlowCode = "";
      this.OrderId = 0;
      this.OrderCode = "";
      this.ItemId = 0;
      this.ItemName = "";
      this.$refs.attachAndTrace.clear();
    },
    reqFlow(params) {
      this.flowLoading = true;
      this.flowFailed = false;
      this.$axios
        .get("/Api/BtmsFlow/GetLast", {
          params: params,
        })
        .then((res) => {
          this.flowLoading = false;
          if (res.result) {
            this.flowFailed = false;
            this.flow = res.data;
            this.$refs.attachAndTrace.init(this.flow.OrderId, this.flow.Id);
          } else {
            this.flowFailed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.flowLoading = false;
          this.flowFailed = true;
          this.$Message.error(err.errormsg);
        });
    },
    toConfirm() {
      this.$refs.ConfirmTestingSyllabusApply.open(this.flow.Id);
    },
    onConfirm() {
      this.reqFlow();
      this.$emit("on-flow");
    },
  },
};
</script>
<style lang="less">
.outline-change-drawer {
  .ivu-drawer-body {
    padding: 0;
  }
  .sub-titles {
    font-weight: bold;
    position: relative;
    height: 24px;
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 2em;
      background-color: #2d8cf0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>