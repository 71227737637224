<template>
  <Drawer
    :mask-closable="false"
    :transfer="false"
    @on-close="close"
    class="bk-sub-drawer"
    id="SubItem"
    v-model="flag"
    width="900"
  >
    <div class="ivu-drawer-header-inner" slot="header">
      {{parent.OrderCode}} - {{parent.ItemName}} - 子项目&nbsp;
      <Button @click="req" class="ivu-btn-xs" icon="md-refresh"></Button>&nbsp;
      <Button
        @click="toAdd"
        class="ivu-btn-xs"
        icon="md-add"
        type="primary"
        v-if="$able.map.BtmsTestItemUpd"
      ></Button>
    </div>
    <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
  </Drawer>
</template>
<script>
export default {
  data() {
    return {
      parent: {},
      // ——————————
      flag: false,
      tableData: [],
      tableCols: [
        { title: "序号", key: "Sequence", width: 60 },
        { title: "项目名称", key: "Name" },
        // { title: "项目编码", key: "Code" },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return [
              h("Badge", {
                props: {
                  status:
                    this.$designConst.orderItems.StatusBadge[params.row.Status],
                  text: this.$designConst.orderItems.Status[params.row.Status],
                  // text: params.row.StatusDescr,
                },
              }),
            ];
          },
        },
        {
          title: "计划时间",
          width: 120,
          render: (h, params) => {
            return [
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: params.row.PlanBeginTime ? "#515a6e" : "#c5c8ce",
                  },
                },
                "开始：" +
                  (params.row.PlanBeginTime
                    ? params.row.PlanBeginTime.substring(0, 10)
                    : "--")
              ),
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: params.row.PlanEndTime ? "#515a6e" : "#c5c8ce",
                  },
                },
                "结束：" +
                  (params.row.PlanEndTime
                    ? params.row.PlanEndTime.substring(0, 10)
                    : "--")
              ),
            ];
          },
        },
        {
          title: "实际时间",
          width: 120,
          render: (h, params) => {
            let color1 = "#c5c8ce"
            if (params.row.BeginTime) {
              if (params.row.PlanBeginTime) {
                color1 = params.row.PlanBeginTime.substring(0, 10).replace(/\-/g, "") < params.row.BeginTime.substring(0, 10).replace(/\-/g, "") ? "#ed4014" : "#19be6b"
              } else {
                color1 = "#515a6e"
              }
            }
            let color2 = "#c5c8ce"
            if (params.row.EndTime) {
              if (params.row.PlanEndTime) {
                color2 = params.row.PlanEndTime.substring(0, 10).replace(/\-/g, "") < params.row.EndTime.substring(0, 10).replace(/\-/g, "") ? "#ed4014" : "#19be6b"
              } else {
                color2 = "#515a6e"
              }
            }
            return [
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: color1,
                  },
                },
                "开始：" +
                  (params.row.BeginTime
                    ? params.row.BeginTime.substring(0, 10)
                    : "--")
              ),
              h(
                "div",
                {
                  style: {
                    "font-size": "12px",
                    color: color2,
                  },
                },
                "结束：" +
                  (params.row.EndTime
                    ? params.row.EndTime.substring(0, 10)
                    : "--")
              ),
            ];
          },
        },
        { title: "检测工程师", key: "EngrName", width: 80 },
        {
          title: "分包机构",
          key: "SubContractorName",
          width: 100,
          render: (h, params) =>
            h(
              "span",
              {
                style: {
                  color: params.row.SubContractorName ? "#515a6e" : "#c5c8ce",
                },
              },
              params.row.SubContractorName || "--"
            ),
        },
        {
          title: "操作",
          width: 150,
          align: "right",
          render: (h, params) => {
            let a = [];
            if (this.$able.map.BtmsTestItemCancel && params.row.Status != 100) {
              a.push(
                h(
                  "span",
                  {
                    style: {
                      cursor: "pointer",
                      color: "#2d8cf0",
                      display: "inline-block",
                      "vertical-align": "middle",
                    },
                    on: {
                      click: () => {
                        this.toCanel(params.row);
                      },
                    },
                  },
                  "取消项目"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            a.push(
              h("Icon", {
                props: {
                  type: this.$able.map.BtmsTestItemUpd
                    ? "md-create"
                    : "md-open",
                  size: 18,
                },
                attrs: {
                  title: this.$able.map.BtmsTestItemUpd ? "编辑" : "详情",
                },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.toEdit(params.row.Id);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            if (this.$able.map.BtmsTestItemDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableLoading: false,
    };
  },
  methods: {
    open(parent) {
      this.parent = parent;
      this.flag = true;
      this.req();
    },
    close() {
      this.parent = {};
      this.flag = false;
    },
    req() {
      this.tableLoading = true;
      this.$axios
        .get("/Api/BtmsOrderItems/QueryByOrder", {
          params: {
            OrderId: this.parent.OrderId,
            ParentId: this.parent.ItemId,
          },
        })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.tableData = res.data;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toAdd() {
      this.$emit("to-add-sub", this.parent);
    },
    toEdit(id) {
      let obj = this.$util.copy(this.parent);
      obj.Id = id;
      this.$emit("to-upd-sub", this.parent, id);
    },
    toCanel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否取消：" + this.$util.color(row.Name),
        onOk: () => {
          this.$emit("to-cancel-sub", row.Id);
        },
      });
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.$emit("to-del-sub", row.Id);
        },
      });
    },
  },
};
</script>
<style lang="less">
#SubItem {
  .ivu-table-header th .ivu-table-cell {
    font-size: 12px;
  }
  .ivu-table-cell {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sub-item {
    border-bottom: 1px dashed #e8ecea;
  }
}
</style>