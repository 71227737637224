<template>
  <div>
    <Modal
      :mask-closable="false"
      @on-cancel="close"
      class="start-item-modal"
      title="开启测试"
      v-model="flag"
      width="400"
    >
      <Form :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem :label-width="100" label="开始时间：" prop="BeginTime" required>
          <DatePicker format="yyyy-MM-dd HH:mm" type="datetime" v-model="formd.BeginTime" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemid: 0,
      flag: false,
      posting: false,
      formd: {},
      rules: {
        BeginTime: [
          {
            validator: (rule, value, callback) => {
              this.formd.BeginTime ? callback() : callback(new Error(" "));
            },
          },
        ],
      },
    };
  },
  methods: {
    open(id) {
      this.itemid = id;
      this.formd.BeginTime = new Date();
      this.flag = true;
    },
    close() {
      this.id = 0;
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      this.$axios
        .post("/Api/BtmsOrderItems/Start", {
          ItemId: this.itemid,
          BeginTime: this.formd.BeginTime.format("yyyy-MM-dd hh:mm"),
        })
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("开启成功");
            this.close();
            this.$emit("on-flow");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.start-item-modal {
  .ivu-modal-footer {
    border-top: 0;
  }
}
</style>
