<template>
  <div id="OrderItemStop">
    <Modal :mask-closable="false" @on-cancel="close" title="暂停测试" v-model="flag" width="400">
      <Form :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem label="暂停时间：" prop="StopTime" required>
          <DatePicker format="yyyy-MM-dd HH:mm" type="datetime" v-model="formd.StopTime" />
        </FormItem>
        <FormItem label="暂停原因：" prop="Reason" required style="margin:0">
          <Input :rows="3" type="textarea" v-model="formd.Reason" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      itemid: 0,
      flag: false,
      posting: false,
      formd: {},
      rules: {
        StopTime: [
          {
            validator: (rule, value, callback) => {
              this.formd.StopTime ? callback() : callback(new Error(" "));
            },
          },
        ],
        Reason: [{ required: true, message: " " }],
      },
    };
  },
  methods: {
    open(id) {
      this.itemid = id;
      this.formd.StopTime = new Date();
      this.flag = true;
    },
    close() {
      this.itemid = 0;
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      this.$axios
        .post("/Api/BtmsOrderItems/Stop", {
          ItemId: this.itemid,
          StopTime: this.formd.StopTime.format("yyyy-MM-dd hh:mm"),
          Reason: this.formd.Reason,
        })
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("暂停成功");
            this.close();
            this.$emit("on-flow");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
