<template>
  <div class="pushed-pre-start">
    <div class="bk-drawer-body">
      <Alert style="padding: 8px">{{ flow.Tips }}</Alert>
      <Form @submit.native.prevent>
        <FormItem label="发起时间：">{{ flow.CreateTime }}</FormItem>
        <FormItem label="委托方：">{{ flow.PartyaActionTips }}</FormItem>
        <FormItem label="进度反馈要求：">
          <div class="bk-loading-line" v-if="itemLoading">
            <Icon type="ios-loading"></Icon>
            <span>loading</span>
          </div>
          <Icon @click="reqItem" color="#808695" size="18" type="md-refresh" v-if="itemFailed" />
          <span v-if="!itemLoading">
            <span v-if="item.ProgressUploadReq">
              {{
              item.ProgressUploadReq
              }}
            </span>
            <span class="text-sub" v-else>待委托方提交</span>
          </span>
        </FormItem>
        <FormItem label="数据发送要求：">
          <div class="bk-loading-line" v-if="itemLoading">
            <Icon type="ios-loading"></Icon>
            <span>loading</span>
          </div>
          <Icon @click="reqItem" color="#808695" size="18" type="md-refresh" v-if="itemFailed" />
          <span v-if="!itemLoading">
            <span v-if="item.DataUploadReq">{{ item.DataUploadReq }}</span>
            <span class="text-sub" v-else>待委托方提交</span>
          </span>
        </FormItem>
        <div style="position: relative">
          <Spin class="bk-loading" fix v-if="attachLoading != 0">
            <div>
              <Icon type="ios-loading"></Icon>
              <div v-if="attachLoading == -1">删除图片...</div>
              <div v-if="attachLoading == 1">加载图片...</div>
              <div v-if="attachLoading == 2">上传图片...</div>
            </div>
          </Spin>
          <FormItem label="样品安装接线：">
            <div class="attas">
              <input
                @change="onFiles1"
                accept="image/*"
                multiple
                ref="fileInput1"
                style="display: none"
                type="file"
              />
              <div
                @click="
                  () => {
                    $refs.fileInput1.click();
                  }
                "
                class="bk-add-file"
                style="margin-right: 10px"
                v-if="flow.Status < 2"
              >
                <Icon color="#808695" size="20" type="md-add"></Icon>
              </div>
              <div :key="`img-${i}`" class="atta" v-for="(img, i) in imgs1">
                <img :src="img" @click="viewImgs1(i)" alt />
                <Icon
                  @click="delFile1(i)"
                  class="icon-remove"
                  color="#ed4014"
                  size="20"
                  type="md-close-circle"
                  v-if="flow.Status < 2"
                />
              </div>
            </div>
          </FormItem>
          <FormItem label="工步设置:">
            <div class="attas">
              <input
                @change="onFiles2"
                accept="image/*"
                multiple
                ref="fileInput2"
                style="display: none"
                type="file"
              />
              <div
                @click="
                  () => {
                    $refs.fileInput2.click();
                  }
                "
                class="bk-add-file"
                style="margin-right: 10px"
                v-if="flow.Status < 2"
              >
                <Icon color="#808695" size="20" type="md-add"></Icon>
              </div>
              <div :key="`img-${i}`" class="atta" v-for="(img, i) in imgs2">
                <img :src="img" @click="viewImgs2(i)" alt />
                <Icon
                  @click="delFile2(i)"
                  class="icon-remove"
                  color="#ed4014"
                  size="20"
                  type="md-close-circle"
                  v-if="flow.Status < 2"
                />
              </div>
            </div>
          </FormItem>
        </div>
      </Form>
      <attach-and-trace ref="attachAndTrace" />
    </div>
    <div
      class="bk-drawer-footer"
      v-if="flow.Status == 2 && item.Status == 2 && $able.map.BtmsTestItemStart"
    >
      <Button @click="toStart" type="info">开启测试</Button>
    </div>
  </div>
</template>
<script>
import attachAndTrace from "../../../components/attach-and-trace.vue";
export default {
  components: { attachAndTrace },
  props: {
    flow: Object,
  },
  data() {
    return {
      itemLoading: false,
      itemFailed: false,
      item: {},
      attachLoading: 0,
      files1: [],
      imgs1: [],
      files2: [],
      imgs2: [],
    };
  },
  mounted: function () {
    this.reqItem();
    this.reqAttach();
    this.$refs.attachAndTrace.init(this.flow.OrderId, this.flow.Id, {
      attachFlag: false,
    });
  },
  methods: {
    reqItem() {
      this.itemLoading = true;
      this.itemFailed = false;
      this.$axios
        .get("/Api/BtmsOrderItems/GetById", {
          params: { Id: this.flow.ItemId },
        })
        .then((res) => {
          this.itemLoading = false;
          if (res.result) {
            this.item = res.data;
            this.itemFailed = false;
          } else {
            this.itemFailed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.itemLoading = false;
          this.itemFailed = true;
          this.$Message.error(err.errormsg);
        });
    },

    reqAttach() {
      this.attachLoading = 1;
      this.$axios
        .get("/Api/DataAttachment/QueryForFlow", {
          params: {
            OrderId: this.flow.OrderId,
            FlowId: this.flow.Id,
          },
        })
        .then((res) => {
          this.attachLoading = 0;
          if (res.result) {
            let imgs1 = [],
              files1 = [],
              imgs2 = [],
              files2 = [];
            for (let a of res.data) {
              if (a.CategorySubCode == "WireSplice") {
                imgs1.push(a.Url);
                files1.push(a);
              } else if (a.CategorySubCode == "ProcessStep") {
                imgs2.push(a.Url);
                files2.push(a);
              }
            }
            this.imgs1 = imgs1;
            this.files1 = files1;
            this.imgs2 = imgs2;
            this.files2 = files2;
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attachLoading = 0;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onFiles1(e) {
      let n = [];
      for (let i = 0; i < e.target.files.length; i++) {
        n.push(e.target.files[i]);
      }
      e.target.value = "";
      this.saveAttas("WireSplice", n);
    },
    delFile1(i) {
      console.log(this.files1, i);
      this.toDeAttasl(this.files1[i].Id);
    },
    viewImgs1(i) {
      this.$root.$emit("view-imgs", this.imgs1, i);
    },
    onFiles2(e) {
      if (e.target.files.length > 0) {
        let n = [];
        for (let i = 0; i < e.target.files.length; i++) {
          n.push(e.target.files[i]);
        }
        e.target.value = "";
        this.saveAttas("ProcessStep", n);
      }
    },
    delFile2(i) {
      this.toDeAttasl(this.files2[i].Id);
    },
    viewImgs2(i) {
      this.$root.$emit("view-imgs", this.imgs2, i);
    },
    // 保存附件
    saveAttas(subcode, files) {
      let formdata = new FormData();
      formdata.append("OrderId", this.flow.OrderId);
      formdata.append("FlowId", this.flow.Id);
      formdata.append("CategorySubCode", subcode);
      for (let file of files) {
        formdata.append(subcode, file);
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.attachLoading = 2;
      this.$axios
        .post("/File/PostFlowObject", formdata, config)
        .then((res) => {
          this.attachLoading = 0;
          if (res.result) {
            this.$Message.success("保存成功");
            this.reqAttach();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attachLoading = 0;
          this.$Message.error(err.errormsg);
        });
    },
    toDeAttasl(id) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除图片",
        onOk: () => {
          this.deleteAttas(id);
        },
      });
    },
    deleteAttas(id) {
      this.attachLoading = -1;
      this.$axios
        .post("/Api/DataAttachment/Delete", { Ids: id })
        .then((res) => {
          if (res.result) {
            this.reqAttach();
          } else {
            this.attachLoading = 0;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.attachLoading = 0;
          this.$Message.error(err.errormsg);
        });
    },
    toStart() {
      this.$emit("to-start");
    },
  },
};
</script>
<style lang="less">
.pushed-pre-start {
  .ivu-form-item {
    margin-bottom: 0;
  }
  .attas {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .atta {
    list-style: none;
    width: 58px;
    height: 58px;
    float: left;
    margin: 0 10px 10px 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
      cursor: pointer;
    }
    img:hover {
      box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
    }
    .icon-remove {
      position: absolute;
      top: -5px;
      right: -5px;
      cursor: pointer;
    }
  }
}
</style>