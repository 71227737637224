<template>
  <div>
    <Modal
      :styles="{top:'50px'}"
      :title="title"
      @on-cancel="close"
      class="pick-user"
      v-model="flag"
      width="700"
    >
      <Form @submit.native.prevent inline>
        <FormItem :label-width="60" label="姓名" style="margin-bottom:10px">
          <Input style="width:100px;" v-model="searchData1.Name" />
        </FormItem>
        <FormItem :label-width="60" label="实验室" style="margin-bottom:10px" v-if="type==1">
          <Select clearable style="width:100px;" v-model="searchData1.LabId">
            <span slot="prefix">
              <Icon class="bk-load" type="ios-loading" v-if="LabLoading"></Icon>
            </span>
            <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button @click="search" type="primary">搜索</Button>
          <Button @click="clearSearch" style="margin-left:10px" type="default">重置</Button>
        </FormItem>
      </Form>
      <Table
        :columns="cols"
        :data="list"
        :height="tableHeight"
        :highlight-row="!multiple"
        :loading="loading"
        @on-current-change="onCurrentChange"
        @on-row-click="onRowClick"
        @on-select="onSelect"
        @on-select-all="onSelectAll"
        @on-select-all-cancel="unSelectAll"
        @on-select-cancel="unSelect"
      ></Table>
      <div slot="footer">
        <div class="flex">
          <Page
            :current="pi"
            :page-size="ps"
            :total="all"
            @on-change="(index) => onChangePage(index, 0)"
            @on-page-size-change="(size) => onChangePage(1, size)"
            style="flex: 1; text-align: left; line-height: 32px"
          />
          <Button @click="close" type="text">取消</Button>
          <Button @click="ok" type="primary">确定</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import SelectLabId from "@/components/select-labid.js";
export default {
  mixins: [SelectLabId],
  props: {
    title: {
      default: "选择人员",
      type: String,
    },
    multiple: Boolean,
    type: [String, Number],
    status: {
      default: "",
      type: [String, Number],
    },
  },
  data() {
    return {
      tableHeight: 300,
      flag: false,
      loading: false,
      searchData1: {},
      searchData2: {},
      pi: 1,
      ps: 10,
      all: 0,
      list: [],
      cols: [
        { title: "用户名", key: "UserName" },
        { title: "用户帐号", key: "UserCode" },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status: this.$designConst.user.StatusBadge[params.row.Status],
                text: this.$designConst.user.Status[params.row.Status],
              },
            });
          },
        },
      ],
      picked: [],
      obj: {},
    };
  },
  mounted: function () {
    this.tableHeight = this.$root.$el.clientHeight - 240;
    if (this.multiple) {
      this.cols.unshift({ type: "selection", width: 60, align: "center" });
    } else {
      this.cols.unshift({
        title: "\\",
        width: 30,
        render: (h, params) =>
          this.obj[params.row.Id]
            ? h("Icon", {
                props: { type: "md-checkmark", color: "#2d8cf0" },
              })
            : h("span"),
      });
    }
    if (this.type == 1) { // 员工
      this.cols.splice(4, 0, { title: "实验室", key: "LabName" });
      this.cols.splice(4, 0, { title: "部门", key: "DeptName" });
      this.searchData1.LabId = this.$store.state.user.LabId;
    } else if (this.type == 2) { // 客户
      this.cols.splice(4, 0, { title: "公司", key: "ClientName" });
    }
    this.reqLab();
  },
  methods: {
    open(lst) {
      this.flag = true;
      if (lst && lst.length > 0) {
        this.picked = lst;
        this.picked.map((v) => {
          this.obj[v.Id] = true;
        });
      }
      this.reqLst();
    },
    close() {
      this.flag = false;
      this.picked = [];
      this.obj = {};
    },
    ok() {
      let data = this.$util.copy(this.picked);
      this.$emit("on-ok", data);
      this.close();
    },
    search() {
      this.searchData2 = this.$util.copy(this.searchData1);
      this.pi = 1;
      this.reqLst();
    },
    clearSearch() {
      this.searchData1 = {};
    },
    reqLst() {
      let params = Object.assign(
        {
          pageIndex: this.pi,
          pageSize: this.ps,
          userType: this.type,
          Status: this.status,
        },
        this.searchData2
      );
      let api = "/Api/BeikeUser/QueryPgForSelect"
      if (this.type==2) {
        api = "/Api/BeikeUserClient/QueryPgForSelect"
      }
      this.loading = true;
      this.$axios
        .get(api, { params: params })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.all = res.data.RecordCount;
            this.list = this.handleList(res.data.DataSet);
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$Message.error("网络错误");
        });
    },
    handleList(list) {
      for (let i in list) {
        if (this.obj[list[i].Id]) {
          this.multiple
            ? (list[i]._checked = true)
            : (list[i]._highlight = true);
        } else {
          this.multiple
            ? (list[i]._checked = false)
            : (list[i]._highlight = false);
        }
      }
      return list;
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pi = index;
      }
      if (size > 0) {
        this.ps = size;
      }
      this.reqLst();
    },
    // -----------------------------------------单选
    onCurrentChange(currentRow, oldRow) {
      let obj = {};
      obj[currentRow.Id] = true;
      this.picked = [currentRow];
      this.obj = obj;
    },
    // -----------------------------------------多选
    onRowClick(row, index) {
      if (!this.multiple) {
        return;
      }
      if (!this.obj[row.Id]) {
        this.onSelect(this.picked, row);
      } else {
        this.unSelect(this.picked, row);
      }
      this.list = this.handleList(this.$util.copy(this.list));
    },
    onSelect(selection, row) {
      let picked = this.$util.copy(this.picked);
      if (!this.obj[row.Id]) {
        this.obj[row.Id] = true;
        picked.push(row);
      }
      this.picked = picked;
    },
    onSelectAll(selection) {
      let picked = this.$util.copy(this.picked);
      for (let i in selection) {
        if (!this.obj[selection[i].Id]) {
          this.obj[selection[i].Id] = 1;
          picked.push(selection[i]);
        }
      }
      this.picked = picked;
    },
    unSelect(selection, row) {
      let picked = this.$util.copy(this.picked);
      if (this.obj[row.Id]) {
        this.obj[row.Id] = 0;
        let index = picked.findIndex((v) => v.Id == row.Id);
        if (index != -1) {
          picked.splice(index, 1);
        } else {
          console.log("selContacts: unSelect error");
        }
      }
      this.picked = picked;
    },
    unSelectAll(selection) {
      let picked = this.$util.copy(this.picked);
      let cur_page_obj = {};
      for (let i in this.list) {
        if (this.obj[this.list[i].Id]) {
          this.obj[this.list[i].Id] = 0;
          cur_page_obj[this.list[i].Id] = 1;
        }
      }
      this.picked = picked.filter((v) => !cur_page_obj[v.Id]);
    },
    delSelect(row) {
      let index = this.picked.findIndex((v) => v.Id == row.Id);
      if (index != -1) {
        this.obj[this.picked[index].Id] = false;
        this.picked.splice(index, 1);
        this.list = this.handleList(this.$util.copy(this.list));
      }
    },
    clearSelect() {
      for (let i in this.obj) {
        this.obj[i] = false;
      }
      this.picked = [];
      this.list = this.handleList(this.$util.copy(this.list));
    },
  },
};
</script>