<template>
  <div id="itemEdit">
    <Modal
      :mask-closable="false"
      :styles="{top:'20px'}"
      :transfer="false"
      @on-cancel="close"
      class="order-item-edit"
      title="测试项目"
      v-model="flag"
      width="700"
    >
      <div slot="header">
        <p>
          <span v-if="parent.ItemId > 0">{{parent.ItemName}}-子项目</span>
          <span v-else>测试项目</span>
          <span class="text-df text-sub" v-if="id > 0 && !statusAble">
            <Divider type="vertical" />
            {{ parent.ItemId ? $designConst.orderItems.Status[formd.Status] : formd.StatusDescr }}
          </span>
        </p>
      </div>
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <Form
        :label-width="130"
        :model="formd"
        :rules="rules"
        @submit.native.prevent
        inline
        ref="form"
      >
        <FormItem label="委托单：" prop="OrderId" required>
          <Button
            @click="fastPickOrder"
            ghost
            size="small"
            style="margin-bottom: 8px"
            type="primary"
            v-if="ABLE && $store.state.order.Id && parent.ItemId == undefined"
          >快速选择：{{ $store.state.order.Code }}</Button>
          <Input
            :value="formd.OrderCode"
            @on-clear="unPickOrder"
            clearable
            readonly
            v-if="ABLE&&parent.ItemId==undefined"
          >
            <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
          </Input>
          <div v-else>{{formd.OrderCode}}</div>
        </FormItem>
        <FormItem label="项目名称：" prop="Name" required>
          <Input v-if="ABLE" v-model="formd.Name" />
          <div v-else>{{formd.Name}}</div>
        </FormItem>
        <!-- <FormItem label="项目编码：" prop="Code" required>
          <Input v-if="ABLE" v-model="formd.Code" />
          <div v-else>{{formd.Code}}</div>
        </FormItem>-->
        <FormItem label="检测工程师：">
          <Input :value="formd.EngrName" @on-clear="unPickEngr" clearable readonly v-if="ABLE">
            <Button @click="toPickEngr" icon="ios-more" slot="append"></Button>
          </Input>
          <div v-else>{{formd.EngrName}}</div>
        </FormItem>
        <FormItem label="分包机构：">
          <Input
            :value="formd.SubContractorName"
            @on-clear="unPickSubContractor"
            clearable
            readonly
            v-if="ABLE"
          >
            <Button @click="toPickSubContractor" icon="ios-more" slot="append"></Button>
          </Input>
          <div v-else>{{formd.SubContractorName}}</div>
        </FormItem>
        <FormItem label="计划开始时间：">
          <DatePicker v-if="ABLE" v-model="formd.PlanBeginTime" />
          <div v-else>{{formd.PlanBeginTime}}</div>
        </FormItem>
        <FormItem label="计划结束时间：">
          <DatePicker v-if="ABLE" v-model="formd.PlanEndTime" />
          <div v-else>{{formd.PlanEndTime}}</div>
        </FormItem>

        <FormItem label="实际开始时间：">
          <DatePicker v-if="ABLE" v-model="formd.BeginTime" />
          <div v-else>{{formd.BeginTime}}</div>
        </FormItem>
        <FormItem label="实际结束时间：">
          <DatePicker v-if="ABLE" v-model="formd.EndTime" />
          <div v-else>{{formd.EndTime}}</div>
        </FormItem>
        <FormItem label="排序：">
          <Input v-if="ABLE" v-model="formd.Sequence" />
          <div v-else>{{formd.Sequence}}</div>
        </FormItem>
        <FormItem label="状态：" v-if="statusAble">
          <Select v-model="formd.Status">
            <Option :value="0" disabled>{{ $designConst.orderItems.Status[0] }}</Option>
            <Option :value="2">{{ $designConst.orderItems.Status[2] }}</Option>
            <Option :value="3">{{ $designConst.orderItems.Status[3] }}</Option>
            <Option :value="4">{{ $designConst.orderItems.Status[4] }}</Option>
            <Option :value="5">{{ $designConst.orderItems.Status[5] }}</Option>
            <Option :value="6">{{ $designConst.orderItems.Status[6] }}</Option>
            <Option :value="100" disabled>{{ $designConst.orderItems.Status[100] }}</Option>
          </Select>
        </FormItem>
        <FormItem class="long" label="备注：">
          <Input type="textarea" v-if="ABLE" v-model="formd.Remark" />
          <div v-else>{{formd.Remark}}</div>
        </FormItem>
      </Form>
      <div slot="footer" v-if="ABLE">
        <Button @click="close" type="text">取消</Button>
        <Button
          :loading="posting"
          @click="ok(0)"
          ghost
          type="primary"
          v-if="!parent.ItemId&&formd.Status==0"
        >保存为草稿</Button>
        <Button
          :loading="posting"
          @click="ok(1)"
          type="primary"
          v-if="!parent.ItemId&&formd.Status==0"
        >保存并提交</Button>
        <Button
          :loading="posting"
          @click="ok(formd.Status)"
          type="primary"
          v-if="!parent.ItemId&&formd.Status>0"
        >保存</Button>
        <Button :loading="posting" @click="ok(formd.Status)" type="primary" v-if="parent.ItemId">保存</Button>
      </div>
    </Modal>
    <pick-order @on-ok="onPickOrder" ref="PickOrder" />
    <pick-user :type="1" @on-ok="onPickEngr" ref="PickUser" title="选择检测工程师" />
    <pick-trader :type="3" @on-ok="onPickSubContractor" ref="PickTrader" title="选择分包机构" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import PickUser from "../../components/PickUser.vue";
import PickTrader from "../../components/PickTrader.vue";
export default {
  components: { PickUser, PickTrader, PickOrder },
  data() {
    return {
      ABLE: false,
      statusAble: false,
      id: 0,
      parent: {},
      flag: false,
      loading: false,
      failed: false,
      posting: false,
      base: {},
      formd: {},
      rules: {
        OrderId: [{ required: true, type: "number", message: " " }],
        Name: [{ required: true, message: " " }],
        // Code: [{ required: true, message: " " }],
        EngrId: [{ required: true, type: "number", message: " " }],
      },
      status: 0,
    };
  },
  methods: {
    open(id, parent) {
      if (parent) {
        this.parent = parent;
        this.formd.OrderId = parent.OrderId;
        this.formd.OrderCode = parent.OrderCode;
        this.formd.ParentId = parent.ItemId;
        if (id == 0) {
          this.formd.Status = 0;
        } else {
          this.statusAble = true;
        }
      }
      if (id) {
        this.ABLE = this.$able.map.BtmsTestItemUpd;
        this.id = id;
        this.req();
      } else {
        this.ABLE = this.$able.map.BtmsTestItemAdd;
        this.formd.Status = 0;
      }
      this.flag = true;
    },
    close() {
      this.ABLE = false;
      this.statusAble = false;
      this.id = 0;
      this.parent = {};
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.base = {};
    },
    ok(status) {
      this.status = status;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    //
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsOrderItems/GetVo", { params: { Id: this.id } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.base = res.data;
            this.formd = res.data;
            if (this.parent.ItemId && this.base.Status == 100) {
              this.statusAble = false;
            }
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    postData() {
      this.posting = true;
      let api =
        this.id > 0 ? "/Api/BtmsOrderItems/Update" : "/Api/BtmsOrderItems/Add";
      let param = this.$util.copy(this.formd);
      param.Status = this.status;
      param.PlanBeginTime = "";
      if (
        this.formd.PlanBeginTime &&
        typeof this.formd.PlanBeginTime == "object"
      ) {
        param.PlanBeginTime = this.formd.PlanBeginTime.format("yyyy-MM-dd");
      }
      param.PlanEndTime = "";
      if (this.formd.PlanEndTime && typeof this.formd.PlanEndTime == "object") {
        param.PlanEndTime = this.formd.PlanEndTime.format("yyyy-MM-dd");
      }
      param.BeginTime = "";
      if (this.formd.BeginTime && typeof this.formd.BeginTime == "object") {
        param.BeginTime = this.formd.BeginTime.format("yyyy-MM-dd");
      }
      param.EndTime = "";
      if (this.formd.EndTime && typeof this.formd.EndTime == "object") {
        param.EndTime = this.formd.EndTime.format("yyyy-MM-dd");
      }

      this.$axios
        .post(api, param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            if (this.id > 0) {
              this.$Message.success("编辑成功");
              this.formd.ParentId > 0
                ? this.$emit("on-sub")
                : this.$emit("on-upd");
            } else {
              this.$Message.success("新增成功");
              this.formd.ParentId > 0
                ? this.$emit("on-sub")
                : this.$emit("on-add");
            }
            this.close();
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 订单
    fastPickOrder() {
      this.formd.OrderId = this.$store.state.order.Id;
      this.$set(this.formd, "OrderCode", this.$store.state.order.Code);
    },
    toPickOrder() {
      if (this.formd.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.formd.OrderId, Code: this.formd.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.formd.OrderId = lst[0].Id;
        this.$set(this.formd, "OrderCode", lst[0].Code);
      }
    },
    unPickOrder() {
      this.formd.OrderId = "";
      this.$set(this.formd, "OrderCode", "");
    },
    // 工程师
    toPickEngr() {
      if (this.formd.EngrId) {
        this.$refs.PickUser.open([
          { Id: this.formd.EngrId, UserName: this.formd.EngrName },
        ]);
      } else {
        this.$refs.PickUser.open();
      }
    },
    onPickEngr(lst) {
      if (lst[0]) {
        this.formd.EngrId = lst[0].Id;
        this.$set(this.formd, "EngrName", lst[0].UserName);
      }
    },
    unPickEngr() {
      this.formd.EngrId = "";
      this.$set(this.formd, "EngrName", "");
    },
    // 分包机构
    toPickSubContractor() {
      if (this.formd.SubContractorId) {
        this.$refs.PickTrader.open([
          {
            Id: this.formd.SubContractorId,
            ShortName: this.formd.SubContractorName,
          },
        ]);
      } else {
        this.$refs.PickTrader.open();
      }
    },
    onPickSubContractor(lst) {
      if (lst[0]) {
        this.formd.SubContractorId = lst[0].Id;
        this.$set(this.formd, "SubContractorName", lst[0].ShortName);
      }
    },
    unPickSubContractor() {
      this.formd.SubContractorId = "";
      this.$set(this.formd, "SubContractorName", "");
    },
  },
};
</script>
<style lang="less">
.order-item-edit {
  .ivu-form-item {
    margin-right: 0;
    width: 50%;
    margin-bottom: 10px;
  }
  .ivu-form-item.long {
    width: 100%;
  }
}
</style>